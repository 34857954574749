import { action, observable } from 'mobx';

import { EntityDto } from '../services/dto/entityDto';
import { PagedResultDto } from '../services/dto/pagedResultDto';
import seriesService, { SeriesDto, SeriesService, GetAllSeriesInput, UpdateSeriesInput } from '../services/series';

export interface ISeriesStore {
  allSeries: PagedResultDto<SeriesDto>;
  series: SeriesDto;

  delete(input: EntityDto): Promise<void>;
  get(input: EntityDto): Promise<void>;
  getAll(input: GetAllSeriesInput): Promise<void>;
  put(input: UpdateSeriesInput): Promise<void>;
  resetAllSeries(): void;
  resetSeries(): void;
}

export default class SeriesStore implements ISeriesStore {
  @observable allSeries!: PagedResultDto<SeriesDto>;

  @observable series!: SeriesDto;

  private seriesService: SeriesService;

  constructor(seriesSvc: SeriesService = seriesService) {
    this.seriesService = seriesSvc;
  }

  @action
  async delete(params: EntityDto<number>): Promise<void> {
    await this.seriesService.delete(params);

    if (this.allSeries) {
      this.allSeries.items = this.allSeries.items.filter((x) => x.id !== params.id);
      this.allSeries.totalCount -= 1;
    }
  }

  @action
  async get(input: EntityDto): Promise<void> {
    const result = await this.seriesService.get(input);
    this.series = result;
  }

  @action
  async getAll(input: GetAllSeriesInput): Promise<void> {
    const result = await this.seriesService.getAll(input);
    this.allSeries = result;
  }

  @action
  async put(input: UpdateSeriesInput): Promise<void> {
    const result = await this.seriesService.put(input);
    this.series = result;
  }

  @action
  resetAllSeries(): void {
    this.allSeries = undefined;
  }

  @action
  resetSeries(): void {
    this.series = undefined;
  }
}
