import _ from 'lodash';
import { action, observable } from 'mobx';

import commentService, { CommentService, CommentDto, CreateCommentInput, GetAllCommentsInput, UpdateCommentInput } from '../services/comment';
import { EntityDto } from '../services/dto/entityDto';
import { PagedResultDto } from '../services/dto/pagedResultDto';

export interface ICommentStore {
  comments: PagedResultDto<CommentDto>;

  create(input: CreateCommentInput): Promise<void>;
  delete(params: EntityDto): Promise<void>;
  getAll(params: GetAllCommentsInput): Promise<void>;
  resetComments(): void;
  update(input: UpdateCommentInput): Promise<void>;
}

export default class CommentStore implements ICommentStore {
  @observable comments: PagedResultDto<CommentDto>;

  private commentService: CommentService;

  constructor(commentSvc: CommentService = commentService) {
    this.commentService = commentSvc;
  }

  @action
  async getAll(params: GetAllCommentsInput): Promise<void> {
    const result = await this.commentService.getAll(params);
    this.comments = result;
  }

  @action
  async delete(params: EntityDto<number>): Promise<void> {
    await this.commentService.delete(params);
    this.comments.items = this.comments.items.filter((x) => x.id !== params.id);
    this.comments.totalCount -= 1;
  }

  @action
  async create(input: CreateCommentInput): Promise<void> {
    const result = await this.commentService.create(input);
    this.comments.items = _.orderBy([...this.comments.items, result], 'creationTime', 'desc');
    this.comments.totalCount += 1;
  }

  @action
  resetComments(): void {
    this.comments = undefined;
  }

  @action
  async update(input: UpdateCommentInput): Promise<void> {
    const result = await this.commentService.update(input);
    this.comments.items = this.comments.items.map((x) => (x.id === input.id ? result : x));
  }
}
