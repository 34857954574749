import axios from 'axios';
import qs from 'qs';
import AppConsts from '../../lib/appconst';

const http = axios.create({
  baseURL: AppConsts.remoteServiceBaseUrl,
  timeout: 60000,
  paramsSerializer(params) {
    return qs.stringify(params, {
      allowDots: true,
      encode: false,
    });
  },
});

http.interceptors.request.use(
  (config) => {
    let Authorization;
    if (abp.auth.getToken()) {
      Authorization = `Bearer ${abp.auth.getToken()}`;
    }

    return {
      ...config,
      headers: {
        ...(config.headers || {}),
        common: {
          ...(config.headers.common || {}),
          Authorization,
          '.AspNetCore.Culture': abp.utils.getCookieValue('Abp.Localization.CultureName'),
          'Abp.TenantId': abp.multiTenancy.getTenantIdCookie(),
        },
      },
    };
  },
  (error) => Promise.reject(error)
);

export default http;
