import { action, observable } from 'mobx';

import brandService, { BrandDto, BrandService, GetAllBrandsInput, UpdateBrandInput } from '../services/brand';
import { EntityDto } from '../services/dto/entityDto';
import { PagedResultDto } from '../services/dto/pagedResultDto';

export interface IBrandStore {
  brand: BrandDto;
  brands: PagedResultDto<BrandDto>;

  delete(input: EntityDto): Promise<void>;
  get(input: EntityDto): Promise<void>;
  getAll(input: GetAllBrandsInput): Promise<void>;
  put(input: UpdateBrandInput): Promise<void>;
  resetBrand(): void;
  resetBrands(): void;
}

export default class BrandStore implements IBrandStore {
  @observable brand!: BrandDto;

  @observable brands!: PagedResultDto<BrandDto>;

  private brandService: BrandService;

  constructor(brandSvc: BrandService = brandService) {
    this.brandService = brandSvc;
  }

  @action
  async delete(params: EntityDto<number>): Promise<void> {
    await this.brandService.delete(params);

    if (this.brands) {
      this.brands.items = this.brands.items.filter((x) => x.id !== params.id);
      this.brands.totalCount -= 1;
    }
  }

  @action
  async get(input: EntityDto): Promise<void> {
    const result = await this.brandService.get(input);
    this.brand = result;
  }

  @action
  async getAll(input: GetAllBrandsInput): Promise<void> {
    const result = await this.brandService.getAll(input);
    this.brands = result;
  }

  @action
  async put(input: UpdateBrandInput): Promise<void> {
    const result = await this.brandService.put(input);
    this.brand = result;
  }

  @action
  resetBrand(): void {
    this.brand = undefined;
  }

  @action
  resetBrands(): void {
    this.brands = undefined;
  }
}
