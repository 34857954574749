import { action, observable } from 'mobx';
import competitorService, { CompetitorService, UpdateCompetitorInput } from '../services/competitor';
import { EntityDto } from '../services/dto/entityDto';

import { PagedFilteredAndSortedResultRequest } from '../services/dto/pagedFilteredAndSortedResultRequest';
import { PagedResultDto } from '../services/dto/pagedResultDto';
import { ManufacturerDto } from '../services/manufacturer';

export interface ICompetitorStore {
  competitor: ManufacturerDto;
  competitors: PagedResultDto<ManufacturerDto>;

  delete(input: EntityDto): Promise<void>;
  get(input: EntityDto): Promise<void>;
  getAll(input: PagedFilteredAndSortedResultRequest): Promise<void>;
  post(input: EntityDto): Promise<ManufacturerDto>;
  put(input: UpdateCompetitorInput): Promise<void>;
  resetCompetitor(): void;
  resetCompetitors(): void;
}

export default class CompetitorStore implements ICompetitorStore {
  @observable competitor!: ManufacturerDto;

  @observable competitors!: PagedResultDto<ManufacturerDto>;

  private competitorService: CompetitorService;

  constructor(competitorSvc: CompetitorService = competitorService) {
    this.competitorService = competitorSvc;
  }

  @action
  async delete(params: EntityDto<number>): Promise<void> {
    await this.competitorService.delete(params);

    if (this.competitors) {
      this.competitors.items = this.competitors.items.filter((x) => x.id !== params.id);
      this.competitors.totalCount -= 1;
    }
  }

  @action
  async get(input: EntityDto): Promise<void> {
    const result = await this.competitorService.get(input);
    this.competitor = result;
  }

  @action
  async getAll(input: PagedFilteredAndSortedResultRequest): Promise<void> {
    const result = await this.competitorService.getAll(input);
    this.competitors = result;
  }

  post(input: EntityDto): Promise<ManufacturerDto> {
    return this.competitorService.post(input);
  }

  @action
  async put(input: UpdateCompetitorInput): Promise<void> {
    const result = await this.competitorService.put(input);
    this.competitor = result;
  }

  @action
  resetCompetitor(): void {
    this.competitor = undefined;
  }

  @action
  resetCompetitors(): void {
    this.competitors = undefined;
  }
}
