import ServiceBase from './core/serviceBase';
import { EntityDto } from './dto/entityDto';
import { PagedAndSortedRequest } from './dto/pagedAndSortedRequest';
import { PagedResultDto } from './dto/pagedResultDto';
import { RoleDto } from './role';

export type ChangeLanguageRequest = {
  languageName: string;
};

export type CreateUserInput = {
  emailAddress: string;
  fullName?: string;
  isActive: boolean;
  language: string;
  name: string;
  password: string;
  roleNames: string[];
  surname: string;
  timezone: string;
  userName: string;
};

export type GetAllUsersInput = PagedAndSortedRequest & {
  keyword: string;
};

export type UpdateUserInput = EntityDto & {
  emailAddress: string;
  fullName?: string;
  isActive: boolean;
  name: string;
  roleNames: string[];
  surname: string;
  userName: string;
};

export type UserDto = EntityDto & {
  creationTime: Date;
  emailAddress: string;
  fullName?: string;
  isActive: boolean;
  lastLoginTime?: string | Date;
  name: string;
  roleNames: string[];
  surname: string;
  userName: string;
};

export class UserService extends ServiceBase {
  async changeLanguage(changeLanguageRequest: ChangeLanguageRequest): Promise<void> {
    const result = await this.httpSvc.post('api/services/app/User/ChangeLanguage', changeLanguageRequest);
    return result.data;
  }

  async create(input: CreateUserInput): Promise<UserDto> {
    const result = await this.httpSvc.post('api/services/app/User/Create', input);
    return result.data.result;
  }

  async delete(entityDto: EntityDto): Promise<void> {
    await this.httpSvc.delete('api/services/app/User/Delete', { params: entityDto });
  }

  async get(entityDto: EntityDto): Promise<UserDto> {
    const result = await this.httpSvc.get('api/services/app/User/Get', { params: entityDto });
    return result.data.result;
  }

  async getAll(input: GetAllUsersInput): Promise<PagedResultDto<UserDto>> {
    const result = await this.httpSvc.get('api/services/app/User/GetAll', { params: input });
    return result.data.result;
  }

  async getRoles(): Promise<RoleDto[]> {
    const result = await this.httpSvc.get('api/services/app/User/GetRoles');
    return result.data.result.items;
  }

  async update(input: UpdateUserInput): Promise<UserDto> {
    const result = await this.httpSvc.put('api/services/app/User/Update', input);
    return result.data.result;
  }
}

export default new UserService();
