import ServiceBase from './core/serviceBase';
import { EntityDto } from './dto/entityDto';
import { FilterDto } from './dto/filterDto';
import { PagedAndSortedRequest } from './dto/pagedAndSortedRequest';
import { PagedResultDto } from './dto/pagedResultDto';
import { PropertyDto } from './dto/propertyDto';
import { ProductDto } from './product';

export type ComparisonDto = EntityDto & {
  commentCount: number;
  competitorProduct: ProductDto;
  competitorProductDocumentCount: number;
  competitorProductMediaItemCount: number;
  creatorUser: string;
  lineItems: Array<ComparisonLineItemDto>;
  productLibrary: ProductDto;
  productLibraryDocumentCount: number;
  productLibraryMediaItemCount: number;
  warningCount: number;
};

export type ComparisonLineItemDto = EntityDto & {
  competitorProperty: PropertyDto;
  hasComments: boolean;
  hasWarnings: boolean;
  order: number;
  productProperty: PropertyDto;
  rating: number;
};

export type CreateComparisonInput = {
  competitorProductId: number;
  productLibraryId: number;
};

export type GetAllComparisonsInput = PagedAndSortedRequest & {
  competitorProductApplicationTagFilters?: Array<number>;
  competitorProductId?: number;
  competitorProductManufacturerFilters?: Array<number>;
  competitorProductProductTagFilters?: Array<number>;
  competitorProductSeriesFilters?: Array<number>;
  productLibraryApplicationTagFilters?: Array<number>;
  productLibraryId?: number;
  productLibraryManufacturerFilters?: Array<number>;
  productLibraryProductTagFilters?: Array<number>;
  productLibrarySeriesFilters?: Array<number>;
};

export type GetAllComparisonsOutput = PagedResultDto<ComparisonDto> & {
  competitorProductApplicationTags?: Array<FilterDto>;
  competitorProductManufacturers?: Array<FilterDto>;
  competitorProductProductTags?: Array<FilterDto>;
  competitorProductSeries?: Array<FilterDto>;
  productLibraryApplicationTags?: Array<FilterDto>;
  productLibraryManufacturers?: Array<FilterDto>;
  productLibraryProductTags?: Array<FilterDto>;
  productLibrarySeries?: Array<FilterDto>;
};

export type UpdateComparisonInput = EntityDto & {
  lineItems: Array<UpdateComparisonLineItemInput>;
};

export type UpdateComparisonLineItemInput = {
  competitorPropertyId?: number;
  id?: number;
  isDeleted: boolean;
  isDirty: boolean;
  isNew: boolean;
  order: number;
  productPropertyId?: number;
  rating: number;
};

export class ComparisonService extends ServiceBase {
  async delete(params: EntityDto): Promise<void> {
    await this.httpSvc.delete('api/services/app/Comparison/Delete', { params });
  }

  async get(params: EntityDto): Promise<ComparisonDto> {
    const result = await this.httpSvc.get('api/services/app/Comparison/Get', { params });
    return result.data.result;
  }

  async getAll(params: GetAllComparisonsInput): Promise<GetAllComparisonsOutput> {
    const result = await this.httpSvc.get('api/services/app/Comparison/GetAll', { params });
    return result.data.result;
  }

  async post(input: CreateComparisonInput): Promise<ComparisonDto> {
    const result = await this.httpSvc.post('api/services/app/Comparison/Create', input);
    return result.data.result;
  }

  async put(input: UpdateComparisonInput): Promise<void> {
    await this.httpSvc.put('api/services/app/Comparison/Update', input);
  }
}

export default new ComparisonService();
