import ServiceBase from './core/serviceBase';
import { EditPropertyDto } from './dto/editPropertyDto';
import { EntityDto } from './dto/entityDto';
import { PagedFilteredAndSortedResultRequest } from './dto/pagedFilteredAndSortedResultRequest';
import { PagedResultDto } from './dto/pagedResultDto';
import { PropertyDto } from './dto/propertyDto';
import { UpdatePropertyDto } from './dto/updatePropertyDto';
import { ManufacturerDto } from './manufacturer';

export type ManufacturerLibraryPropertyDto = PropertyDto & {
  manufacturerLibraryId: number;
};

export type EditManufacturerLibraryPropertyDto = EditPropertyDto & {
  manufacturerLibraryId: number;
};

export type UpdateManufacturerLibraryPropertyDto = UpdatePropertyDto & {
  manufacturerLibraryId: number;
};

export type UpdateManufacturerLibraryInput = EntityDto & {
  properties: Array<UpdatePropertyDto>;
};

export class ManufacturerLibraryService extends ServiceBase {
  async delete(params: EntityDto): Promise<void> {
    await this.httpSvc.delete('/api/services/app/ManufacturerLibrary/Delete', { params });
  }

  async get(params: EntityDto): Promise<ManufacturerDto> {
    const result = await this.httpSvc.get('/api/services/app/ManufacturerLibrary/Get', { params });
    return result.data.result;
  }

  async getAll(params: PagedFilteredAndSortedResultRequest): Promise<PagedResultDto<ManufacturerDto>> {
    const result = await this.httpSvc.get('/api/services/app/ManufacturerLibrary/GetAll', { params });
    return result.data.result;
  }

  async post(input: EntityDto): Promise<ManufacturerDto> {
    const result = await this.httpSvc.post('/api/services/app/ManufacturerLibrary/Create', input);
    return result.data.result;
  }

  async put(input: UpdateManufacturerLibraryInput): Promise<ManufacturerDto> {
    const result = await this.httpSvc.put('/api/services/app/ManufacturerLibrary/Update', input);
    return result.data.result;
  }
}

export default new ManufacturerLibraryService();
