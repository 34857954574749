import { action, observable } from 'mobx';

import snapshotService, { SnapshotDto, SnapshotService, GetAllSnapshotsInput } from '../services/snapshots';
import { PagedResultDto } from '../services/dto/pagedResultDto';

export interface ISnapshotStore {
  snapshots: PagedResultDto<SnapshotDto>;

  getAll(input: GetAllSnapshotsInput): Promise<void>;
  resetSnapshots(): void;
}

export default class SnapshotStore implements ISnapshotStore {
  @observable snapshots!: PagedResultDto<SnapshotDto>;

  private snapshotService: SnapshotService;

  constructor(snapshotSvc: SnapshotService = snapshotService) {
    this.snapshotService = snapshotSvc;
  }

  @action
  async getAll(input: GetAllSnapshotsInput): Promise<void> {
    const result = await this.snapshotService.getAll(input);
    this.snapshots = result;
  }

  @action
  resetSnapshots(): void {
    this.snapshots = undefined;
  }
}
