import { action, observable } from 'mobx';

import { EntityDto } from '../services/dto/entityDto';
import { PagedResultDto } from '../services/dto/pagedResultDto';
import tenantService, { CreateTenantRequest, GetAllTenantsInput, TenantDto, TenantService } from '../services/tenant';

export interface ITenantStore {
  tenant: TenantDto;
  tenants: PagedResultDto<TenantDto>;

  create(input: CreateTenantRequest): Promise<void>;
  delete(entityDto: EntityDto): Promise<void>;
  get(entityDto: EntityDto): Promise<void>;
  getAll(input: GetAllTenantsInput): Promise<void>;
  resetTenant(): void;
  resetTenants(): void;
  update(updateTenantInput: TenantDto): Promise<void>;
}

export default class TenantStore {
  @observable tenant!: TenantDto;

  @observable tenants!: PagedResultDto<TenantDto>;

  private tenantService: TenantService;

  constructor(tenantSvc: TenantService = tenantService) {
    this.tenantService = tenantSvc;
  }

  @action
  async create(input: CreateTenantRequest): Promise<void> {
    await this.tenantService.create(input);
  }

  @action
  async delete(entityDto: EntityDto): Promise<void> {
    await this.tenantService.delete(entityDto);
    this.tenants.items = this.tenants.items.filter((x) => x.id !== entityDto.id);
  }

  @action
  async get(entityDto: EntityDto): Promise<void> {
    const result = await this.tenantService.get(entityDto);
    this.tenant = result;
  }

  @action
  async getAll(input: GetAllTenantsInput): Promise<void> {
    const result = await this.tenantService.getAll(input);
    this.tenants = result;
  }

  @action
  resetTenant(): void {
    this.tenant = undefined;
  }

  @action
  resetTenants(): void {
    this.tenants = undefined;
  }

  @action
  async update(input: TenantDto): Promise<void> {
    const result = await this.tenantService.update(input);

    this.tenants.items = this.tenants.items.map((x) => {
      if (x.id === input.id) {
        return result;
      }

      return x;
    });
  }
}
