import { action, observable } from 'mobx';

import { EntityDto } from '../services/dto/entityDto';
import { PagedResultDto } from '../services/dto/pagedResultDto';
import roleService, { CreateRoleInput, GetAllRolesInput, PermissionDto, RoleDto, RoleService } from '../services/role';

export interface IRoleStore {
  permissions: Array<PermissionDto>;
  role: RoleDto;
  roles: PagedResultDto<RoleDto>;

  create(input: CreateRoleInput): Promise<void>;
  delete(entityDto: EntityDto): Promise<void>;
  get(entityDto: EntityDto): Promise<void>;
  getAll(input: GetAllRolesInput): Promise<void>;
  getAllPermissions(): Promise<void>;
  resetPermissions(): void;
  resetRole(): void;
  resetRoles(): void;
  update(input: RoleDto): Promise<void>;
}

export default class RoleStore {
  @observable permissions: PermissionDto[];

  @observable role!: RoleDto;

  @observable roles!: PagedResultDto<RoleDto>;

  private roleService: RoleService;

  constructor(roleSvc: RoleService = roleService) {
    this.roleService = roleSvc;
  }

  @action
  async create(input: CreateRoleInput): Promise<void> {
    await this.roleService.create(input);
  }

  @action
  async delete(entityDto: EntityDto): Promise<void> {
    await this.roleService.delete(entityDto);
    this.roles.items = this.roles.items.filter((x: RoleDto) => x.id !== entityDto.id);
  }

  @action
  async get(entityDto: EntityDto): Promise<void> {
    const result = await this.roleService.get(entityDto);
    this.role = result;
  }

  @action
  async getAll(input: GetAllRolesInput): Promise<void> {
    const result = await this.roleService.getAll(input);
    this.roles = result;
  }

  @action
  async getAllPermissions(): Promise<void> {
    const result = await this.roleService.getAllPermissions();
    this.permissions = result;
  }

  @action
  resetPermissions(): void {
    this.permissions = undefined;
  }

  @action
  resetRole(): void {
    this.role = undefined;
  }

  @action
  resetRoles(): void {
    this.roles = undefined;
  }

  @action
  async update(input: RoleDto): Promise<void> {
    const result = await this.roleService.update(input);
    this.roles.items = this.roles.items.map((x: RoleDto) => {
      if (x.id === input.id) {
        return result;
      }

      return x;
    });
  }
}
