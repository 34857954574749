import ServiceBase from './core/serviceBase';
import { EntityDto } from './dto/entityDto';
import { PagedResultDto } from './dto/pagedResultDto';

export type CommentDto = EntityDto & {
  content: string;
  creationTime: Date;
  creatorUser: string;
  creatorUserId: number;
};

export type CreateCommentInput = {
  comparisonLineItemId?: number;
  content: string;
};

export type GetAllCommentsInput = {
  afterUnixTimestamp?: number;
  beforeUnixTimestamp?: number;
  comparisonLineItemId?: number;
  maxResultCount: number;
};

export type UpdateCommentInput = {
  id: number;
  content: string;
};

export class CommentService extends ServiceBase {
  async create(input: CreateCommentInput): Promise<CommentDto> {
    const result = await this.httpSvc.post('/api/services/app/Comment/Create', input);
    return result.data.result;
  }

  async delete(params: EntityDto): Promise<void> {
    await this.httpSvc.delete('/api/services/app/Comment/Delete', { params });
  }

  async getAll(params: GetAllCommentsInput): Promise<PagedResultDto<CommentDto>> {
    const result = await this.httpSvc.get('/api/services/app/Comment/GetAll', { params });
    return result.data.result;
  }

  async update(input: UpdateCommentInput): Promise<CommentDto> {
    const result = await this.httpSvc.put('/api/services/app/Comment/Update', input);
    return result.data.result;
  }
}

export default new CommentService();
