import Bugsnag, { Client, NotifiableError, Event } from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import AppConsts from './appconst';

let bugsnagClient: Client;

export default {
  config: (): void => {
    if (AppConsts.bugsnag.accessToken) {
      bugsnagClient = Bugsnag.start({
        apiKey: AppConsts.bugsnag.accessToken,
        plugins: [new BugsnagPluginReact()],
        autoTrackSessions: false,
        autoDetectErrors: false,
      });
    }
  },
  notify: (err: NotifiableError | Event): void => {
    if (bugsnagClient) {
      if ((err as Event) != null) {
        // eslint-disable-next-line no-underscore-dangle
        bugsnagClient._notify(err as Event);
      } else {
        bugsnagClient.notify(err as NotifiableError);
      }
    } else {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  },
};
