import { action, observable } from 'mobx';
import { BrandDto } from '../services/brand';
import brandLibraryService, { BrandLibraryService, GetAllBrandLibrariesInput, UpdateBrandLibraryInput } from '../services/brandLibrary';
import { EntityDto } from '../services/dto/entityDto';

import { PagedResultDto } from '../services/dto/pagedResultDto';

export interface IBrandLibraryStore {
  brand: BrandDto;
  brands: PagedResultDto<BrandDto>;

  delete(input: EntityDto): Promise<void>;
  get(input: EntityDto): Promise<void>;
  getAll(input: GetAllBrandLibrariesInput): Promise<void>;
  post(input: EntityDto): Promise<BrandDto>;
  put(input: UpdateBrandLibraryInput): Promise<void>;
  resetBrand(): void;
  resetBrands(): void;
}

export default class BrandLibraryStore implements IBrandLibraryStore {
  @observable brand!: BrandDto;

  @observable brands!: PagedResultDto<BrandDto>;

  private brandLibraryService: BrandLibraryService;

  constructor(brandLibrarySvc: BrandLibraryService = brandLibraryService) {
    this.brandLibraryService = brandLibrarySvc;
  }

  @action
  async delete(params: EntityDto<number>): Promise<void> {
    await this.brandLibraryService.delete(params);

    if (this.brands) {
      this.brands.items = this.brands.items.filter((x) => x.id !== params.id);
      this.brands.totalCount -= 1;
    }
  }

  @action
  async get(input: EntityDto): Promise<void> {
    const result = await this.brandLibraryService.get(input);
    this.brand = result;
  }

  @action
  async getAll(input: GetAllBrandLibrariesInput): Promise<void> {
    const result = await this.brandLibraryService.getAll(input);
    this.brands = result;
  }

  post(input: EntityDto): Promise<BrandDto> {
    return this.brandLibraryService.post(input);
  }

  @action
  async put(input: UpdateBrandLibraryInput): Promise<void> {
    const result = await this.brandLibraryService.put(input);
    this.brand = result;
  }

  @action
  resetBrand(): void {
    this.brand = undefined;
  }

  @action
  resetBrands(): void {
    this.brands = undefined;
  }
}
