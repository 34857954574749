import { action, observable } from 'mobx';
import { ManufacturerDto } from '../services/manufacturer';
import manufacturerLibraryService, { ManufacturerLibraryService, UpdateManufacturerLibraryInput } from '../services/manufacturerLibrary';
import { EntityDto } from '../services/dto/entityDto';

import { PagedFilteredAndSortedResultRequest } from '../services/dto/pagedFilteredAndSortedResultRequest';
import { PagedResultDto } from '../services/dto/pagedResultDto';

export interface IManufacturerLibraryStore {
  manufacturer: ManufacturerDto;
  manufacturers: PagedResultDto<ManufacturerDto>;

  delete(input: EntityDto): Promise<void>;
  get(input: EntityDto): Promise<void>;
  getAll(input: PagedFilteredAndSortedResultRequest): Promise<void>;
  post(input: EntityDto): Promise<ManufacturerDto>;
  put(input: UpdateManufacturerLibraryInput): Promise<void>;
  resetManufacturer(): void;
  resetManufacturers(): void;
}

export default class ManufacturerLibraryStore implements IManufacturerLibraryStore {
  @observable manufacturer!: ManufacturerDto;

  @observable manufacturers!: PagedResultDto<ManufacturerDto>;

  private manufacturerLibraryService: ManufacturerLibraryService;

  constructor(manufacturerLibrarySvc: ManufacturerLibraryService = manufacturerLibraryService) {
    this.manufacturerLibraryService = manufacturerLibrarySvc;
  }

  @action
  async delete(params: EntityDto<number>): Promise<void> {
    await this.manufacturerLibraryService.delete(params);

    if (this.manufacturers) {
      this.manufacturers.items = this.manufacturers.items.filter((x) => x.id !== params.id);
      this.manufacturers.totalCount -= 1;
    }
  }

  @action
  async get(input: EntityDto): Promise<void> {
    const result = await this.manufacturerLibraryService.get(input);
    this.manufacturer = result;
  }

  @action
  async getAll(input: PagedFilteredAndSortedResultRequest): Promise<void> {
    const result = await this.manufacturerLibraryService.getAll(input);
    this.manufacturers = result;
  }

  post(input: EntityDto): Promise<ManufacturerDto> {
    return this.manufacturerLibraryService.post(input);
  }

  @action
  async put(input: UpdateManufacturerLibraryInput): Promise<void> {
    const result = await this.manufacturerLibraryService.put(input);
    this.manufacturer = result;
  }

  @action
  resetManufacturer(): void {
    this.manufacturer = undefined;
  }

  @action
  resetManufacturers(): void {
    this.manufacturers = undefined;
  }
}
