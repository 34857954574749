import ServiceBase from './core/serviceBase';

export type ImpersonateRequest = {
  tenantId: number;
  userId: number;
};

export type AuthenticationRequest = {
  userNameOrEmailAddress: string;
  password: string;
  rememberClient: boolean;
};

export type AuthenticationResponse = {
  accessToken: string;
  encryptedAccessToken: string;
  expireInSeconds: number;
  userId: number;
};

export class TokenAuthService extends ServiceBase {
  async backToAccount(): Promise<AuthenticationResponse> {
    const result = await this.httpSvc.post('api/TokenAuth/BackToAccount');
    return result.data.result;
  }

  async impersonate(impersonateRequest: ImpersonateRequest): Promise<AuthenticationResponse> {
    const result = await this.httpSvc.post('api/TokenAuth/Impersonate', impersonateRequest);
    return result.data.result;
  }

  async authenticate(authenticationRequest: AuthenticationRequest): Promise<AuthenticationResponse> {
    const result = await this.httpSvc.post('api/TokenAuth/Authenticate', authenticationRequest);
    return result.data.result;
  }
}

export default new TokenAuthService();
