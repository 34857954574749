import ServiceBase from './core/serviceBase';

export type ChangePasswordRequest = {
  currentPassword: string;
  newPassword: string;
};

export type ResetPasswordRequest = {
  password: string;
  resetCode: string;
  tenantId?: number;
};

export type ProfileResponse = {
  emailAddress: string;
  language: string;
  name: string;
  surname: string;
  timezone: string;
  userName: string;
};

export class ProfileService extends ServiceBase {
  async changePassword(changePasswordRequest: ChangePasswordRequest): Promise<ProfileResponse> {
    const result = await this.httpSvc.post('api/services/app/Profile/ChangePassword', changePasswordRequest);
    return result.data.result;
  }

  async get(): Promise<ProfileResponse> {
    const result = await this.httpSvc.get('api/services/app/Profile/Get');
    return result.data.result;
  }

  async resetPassword(resetPasswordRequest: ResetPasswordRequest): Promise<void> {
    await this.httpSvc.post('api/services/app/Profile/ResetPassword', resetPasswordRequest);
  }

  async update(profileResponse: ProfileResponse): Promise<ProfileResponse> {
    const result = await this.httpSvc.put('api/services/app/Profile/Update', profileResponse);
    return result.data.result;
  }
}

export default new ProfileService();
