import { action, observable } from 'mobx';

import { EntityDto } from '../services/dto/entityDto';
import { PagedFilteredAndSortedProductResponse } from '../services/dto/pagedFilteredAndSortedProductResponse';

import productService, { GetAllProductsInput, ProductDto, ProductService, UpdateProductInput } from '../services/product';

export interface IProductStore {
  product: ProductDto;
  products: PagedFilteredAndSortedProductResponse<ProductDto>;

  delete(input: EntityDto): Promise<void>;
  get(input: EntityDto): Promise<void>;
  getAll(input: GetAllProductsInput): Promise<void>;
  put(input: UpdateProductInput): Promise<void>;
  resetProduct(): void;
  resetProducts(): void;
}

export default class ProductStore implements IProductStore {
  @observable product!: ProductDto;

  @observable products!: PagedFilteredAndSortedProductResponse<ProductDto>;

  private productService: ProductService;

  constructor(productSvc: ProductService = productService) {
    this.productService = productSvc;
  }

  @action
  async delete(params: EntityDto<number>): Promise<void> {
    await this.productService.delete(params);

    if (this.products) {
      this.products.items = this.products.items.filter((x) => x.id !== params.id);
      this.products.totalCount -= 1;
    }
  }

  @action
  async get(input: EntityDto): Promise<void> {
    const result = await this.productService.get(input);
    this.product = result;
  }

  @action
  async getAll(input: GetAllProductsInput): Promise<void> {
    const result = await this.productService.getAll(input);
    this.products = result;
  }

  @action
  async put(input: UpdateProductInput): Promise<void> {
    const result = await this.productService.put(input);
    this.product = result;
  }

  @action
  resetProduct(): void {
    this.product = undefined;
  }

  @action
  resetProducts(): void {
    this.products = undefined;
  }
}
