import {
  AimOutlined,
  CodepenOutlined,
  FileSearchOutlined,
  HddOutlined,
  HomeOutlined,
  InfoCircleOutlined,
  ProfileOutlined,
  ShopOutlined,
  TagsOutlined,
} from '@ant-design/icons';
import React from 'react';
import { RouteProps } from 'react-router-dom';

import LoadableComponent from '../components/Loadable/index';

export type AbpRouteProps = RouteProps & {
  icon?: React.ReactNode;
  isLayout?: boolean;
  isProtected?: boolean;
  name: string;
  permission?: string;
  showInMenu?: boolean;
  title?: string;
};

export const UserRouter: AbpRouteProps[] = [
  {
    path: '/user',
    name: 'user',
    title: 'User',
    component: LoadableComponent(() => import('../components/Layout/UserLayout')),
    isLayout: true,
    showInMenu: false,
  },
  {
    path: '/user/login',
    name: 'login',
    title: 'LogIn',
    component: LoadableComponent(() => import('../scenes/Login')),
    showInMenu: false,
  },
  {
    path: '/user/forgot',
    name: 'forgotPassword',
    title: 'Forgot Password',
    component: LoadableComponent(() => import('../scenes/RequestPasswordReset')),
    showInMenu: false,
  },
  {
    path: '/user/password',
    name: 'resetPassword',
    title: 'Reset Password',
    component: LoadableComponent(() => import('../scenes/ResetPassword')),
    showInMenu: false,
  },
];

export const AppRouters: AbpRouteProps[] = [
  // MENU ROUTES
  // ----------------------------
  // {
  //   component: LoadableComponent(() => import('../scenes/Dashboard')),
  //   icon: <HomeOutlined />,
  //   name: 'dashboard',
  //   path: '/dashboard',
  //   permission: '',
  //   showInMenu: true,
  //   title: 'Dashboard',
  // },
  {
    component: LoadableComponent(() => import('../scenes/Competitors/List')),
    icon: <AimOutlined />,
    isProtected: true,
    name: 'competitors-list',
    path: '/competitors',
    permission: 'Pages.Competitors_ReadOnly',
    showInMenu: true,
    title: 'Competitors',
  },
  {
    component: LoadableComponent(() => import('../scenes/CompetitorProducts/List')),
    icon: <FileSearchOutlined />,
    isProtected: true,
    name: 'competitor-products-list',
    path: '/competitor-products',
    permission: 'Pages.CompetitorProducts_ReadOnly',
    showInMenu: true,
    title: 'CompetitorProducts',
  },
  {
    component: LoadableComponent(() => import('../scenes/Manufacturers/List')),
    icon: <ShopOutlined />,
    isProtected: true,
    name: 'manufacturers-list',
    path: '/manufacturers',
    permission: 'Pages.Manufacturers',
    showInMenu: true,
    title: 'Manufacturers',
  },
  {
    component: LoadableComponent(() => import('../scenes/ManufacturerLibrary/List')),
    icon: <ShopOutlined />,
    isProtected: true,
    name: 'manufacturer-library-list',
    path: '/manufacturer-library',
    permission: 'Pages.ManufacturerLibrary_ReadOnly',
    showInMenu: true,
    title: 'Manufacturers',
  },
  {
    component: LoadableComponent(() => import('../scenes/Brands/List')),
    icon: <TagsOutlined />,
    isProtected: true,
    name: 'brands-list',
    path: '/brands',
    permission: 'Pages.Brands',
    showInMenu: true,
    title: 'Brands',
  },
  {
    component: LoadableComponent(() => import('../scenes/BrandLibrary/List')),
    icon: <TagsOutlined />,
    isProtected: true,
    name: 'brand-library-list',
    path: '/brand-library',
    permission: 'Pages.BrandLibrary_ReadOnly',
    showInMenu: true,
    title: 'Brands',
  },
  {
    component: LoadableComponent(() => import('../scenes/Series/List')),
    icon: <CodepenOutlined />,
    isProtected: true,
    name: 'series-list',
    path: '/series',
    permission: 'Pages.Series',
    showInMenu: true,
    title: 'Series',
  },
  {
    component: LoadableComponent(() => import('../scenes/SeriesLibrary/List')),
    icon: <CodepenOutlined />,
    isProtected: true,
    name: 'series-library-list',
    path: '/series-library',
    permission: 'Pages.SeriesLibrary_ReadOnly',
    showInMenu: true,
    title: 'Series',
  },
  {
    component: LoadableComponent(() => import('../scenes/Products/List')),
    icon: <CodepenOutlined />,
    isProtected: true,
    name: 'products-list',
    path: '/products',
    permission: 'Pages.Products',
    showInMenu: true,
    title: 'Products',
  },
  {
    component: LoadableComponent(() => import('../scenes/ProductLibrary/List')),
    icon: <CodepenOutlined />,
    isProtected: true,
    name: 'product-library-list',
    path: '/product-library',
    permission: 'Pages.ProductLibrary_ReadOnly',
    showInMenu: true,
    title: 'Products',
  },
  {
    component: LoadableComponent(() => import('../scenes/Monitoring/List')),
    icon: <FileSearchOutlined />,
    isProtected: true,
    name: 'monitoring-list',
    path: '/monitoring',
    permission: 'Pages.Monitoring',
    showInMenu: true,
    title: 'Monitoring',
  },
  {
    component: LoadableComponent(() => import('../components/Hangfire')),
    icon: <HddOutlined />,
    isProtected: true,
    name: 'hangfire',
    path: '/hangfire',
    permission: 'Pages.Administration_Hangfire',
    showInMenu: true,
    title: 'Hangfire',
  },
  {
    component: LoadableComponent(() => import('../scenes/Roles')),
    isProtected: true,
    name: 'role',
    path: '/roles',
    permission: 'Pages.Roles',
    showInMenu: false,
    title: 'Roles',
  },
  {
    component: LoadableComponent(() => import('../scenes/Tenants')),
    isProtected: true,
    name: 'tenant',
    path: '/tenants',
    permission: 'Pages.Tenants',
    showInMenu: false,
    title: 'Tenants',
  },
  {
    component: LoadableComponent(() => import('../scenes/Users')),
    isProtected: true,
    name: 'user',
    path: '/users',
    permission: 'Pages.Users',
    showInMenu: false,
    title: 'Users',
  },

  // NON-MENU ROUTES
  // ----------------------------
  {
    component: LoadableComponent(() => import('../components/Layout/AppLayout')),
    exact: true,
    icon: <HomeOutlined />,
    isLayout: true,
    name: 'home',
    path: '/',
    permission: '',
    showInMenu: false,
    title: 'Home',
  },
  {
    component: LoadableComponent(() => import('../scenes/Brands/Edit')),
    isProtected: true,
    name: 'brands-edit',
    path: '/brands/:brandId/edit',
    permission: 'Pages.Brands',
    showInMenu: false,
    title: 'BrandEdit',
  },
  {
    component: LoadableComponent(() => import('../scenes/Brands/Show')),
    isProtected: true,
    name: 'brands-show',
    path: '/brands/:brandId',
    permission: 'Pages.Brands',
    showInMenu: false,
    title: 'Brand',
  },
  {
    component: LoadableComponent(() => import('../scenes/BrandLibrary/Show')),
    isProtected: true,
    name: 'brand-library-show',
    path: '/brand-library/:brandId',
    permission: 'Pages.BrandLibrary_ReadOnly',
    showInMenu: false,
    title: 'Brand',
  },
  {
    component: LoadableComponent(() => import('../scenes/BrandLibrary/Edit')),
    isProtected: true,
    name: 'brand-library-edit',
    path: '/brand-library/:brandId/edit',
    permission: 'Pages.BrandLibrary',
    showInMenu: false,
    title: 'BrandEdit',
  },
  {
    component: LoadableComponent(() => import('../scenes/Comparisons/Show')),
    isProtected: true,
    name: 'product-comparison',
    path: '/comparisons/:comparisonId',
    permission: 'Pages.Comparisons_ReadOnly',
    showInMenu: false,
    title: 'ProductComparison',
  },
  {
    component: LoadableComponent(() => import('../scenes/Comparisons/Edit')),
    isProtected: true,
    name: 'product-comparison-edit',
    path: '/comparisons/:comparisonId/edit',
    permission: 'Pages.Comparisons',
    showInMenu: false,
    title: 'ProductComparisonEdit',
  },
  {
    component: LoadableComponent(() => import('../scenes/CompetitorProducts/Edit')),
    isProtected: true,
    name: 'competitor-products-edit',
    path: '/competitor-products/:competitorProductId/edit',
    permission: 'Pages.CompetitorProducts',
    showInMenu: false,
    title: 'ProductEdit',
  },
  {
    component: LoadableComponent(() => import('../scenes/CompetitorProducts/Show')),
    isProtected: true,
    name: 'competitor-products-show',
    path: '/competitor-products/:competitorProductId',
    permission: 'Pages.CompetitorProducts_ReadOnly',
    showInMenu: false,
    title: 'CompetitorProduct',
  },
  {
    component: LoadableComponent(() => import('../scenes/Competitors/Show')),
    isProtected: true,
    name: 'competitors-show',
    path: '/competitors/:competitorId',
    permission: 'Pages.Competitors_ReadOnly',
    showInMenu: false,
    title: 'Competitor',
  },
  {
    component: LoadableComponent(() => import('../scenes/Competitors/Edit')),
    isProtected: true,
    name: 'competitors-edit',
    path: '/competitors/:competitorId/edit',
    permission: 'Pages.Competitors',
    showInMenu: false,
    title: 'CompetitorEdit',
  },
  {
    component: LoadableComponent(() => import('../components/Logout')),
    icon: <InfoCircleOutlined />,
    isProtected: true,
    name: 'logout',
    path: '/logout',
    permission: '',
    showInMenu: false,
    title: 'Logout',
  },
  {
    component: LoadableComponent(() => import('../scenes/Manufacturers/Show')),
    isProtected: true,
    name: 'manufacturers-show',
    path: '/manufacturers/:manufacturerId',
    permission: 'Pages.Manufacturers',
    showInMenu: false,
    title: 'Manufacturer',
  },
  {
    component: LoadableComponent(() => import('../scenes/Manufacturers/Edit')),
    isProtected: true,
    name: 'manufacturers-edit',
    path: '/manufacturers/:manufacturerId/edit',
    permission: 'Pages.Manufacturers',
    showInMenu: false,
    title: 'ManufacturerEdit',
  },
  {
    component: LoadableComponent(() => import('../scenes/ManufacturerLibrary/Show')),
    isProtected: true,
    name: 'manufacturer-library-show',
    path: '/manufacturer-library/:manufacturerId',
    permission: 'Pages.ManufacturerLibrary_ReadOnly',
    showInMenu: false,
    title: 'Manufacturer',
  },
  {
    component: LoadableComponent(() => import('../scenes/ManufacturerLibrary/Edit')),
    isProtected: true,
    name: 'manufacturer-library-edit',
    path: '/manufacturer-library/:manufacturerId/edit',
    permission: 'Pages.ManufacturerLibrary',
    showInMenu: false,
    title: 'ManufacturerEdit',
  },
  {
    component: LoadableComponent(() => import('../scenes/Monitoring/Show')),
    isProtected: true,
    name: 'monitoring-show',
    path: '/monitoring/:monitoringId',
    permission: 'Pages.Monitoring',
    showInMenu: false,
    title: 'Monitoring',
  },
  {
    component: LoadableComponent(() => import('../scenes/Products/Edit')),
    isProtected: true,
    name: 'products-edit',
    path: '/products/:productId/edit',
    permission: 'Pages.Products',
    showInMenu: false,
    title: 'ProductEdit',
  },
  {
    component: LoadableComponent(() => import('../scenes/Products/Show')),
    isProtected: true,
    name: 'products-show',
    path: '/products/:productId',
    permission: 'Pages.Products',
    showInMenu: false,
    title: 'Product',
  },
  {
    component: LoadableComponent(() => import('../scenes/ProductLibrary/Edit')),
    isProtected: true,
    name: 'product-library-edit',
    path: '/product-library/:productId/edit',
    permission: 'Pages.ProductLibrary',
    showInMenu: false,
    title: 'ProductEdit',
  },
  {
    component: LoadableComponent(() => import('../scenes/ProductLibrary/Show')),
    isProtected: true,
    name: 'product-library-show',
    path: '/product-library/:productId',
    permission: 'Pages.ProductLibrary_ReadOnly',
    showInMenu: false,
    title: 'Product',
  },
  {
    component: LoadableComponent(() => import('../scenes/Profile')),
    icon: <ProfileOutlined />,
    isProtected: true,
    name: 'profile',
    path: '/profile',
    showInMenu: false,
    title: 'Profile',
  },
  {
    component: LoadableComponent(() => import('../scenes/Series/Edit')),
    isProtected: true,
    name: 'series-edit',
    path: '/series/:seriesId/edit',
    permission: 'Pages.Series',
    showInMenu: false,
    title: 'SeriesEdit',
  },
  {
    component: LoadableComponent(() => import('../scenes/Series/Show')),
    isProtected: true,
    name: 'series-show',
    path: '/series/:seriesId',
    permission: 'Pages.Series',
    showInMenu: false,
    title: 'Series',
  },
  {
    component: LoadableComponent(() => import('../scenes/SeriesLibrary/Edit')),
    isProtected: true,
    name: 'series-library-edit',
    path: '/series-library/:seriesId/edit',
    permission: 'Pages.SeriesLibrary',
    showInMenu: false,
    title: 'SeriesEdit',
  },
  {
    component: LoadableComponent(() => import('../scenes/SeriesLibrary/Show')),
    isProtected: true,
    name: 'series-library-show',
    path: '/series-library/:seriesId',
    permission: 'Pages.SeriesLibrary_ReadOnly',
    showInMenu: false,
    title: 'Series',
  },
  {
    path: '/exception/:type',
    permission: '',
    title: 'exception',
    name: 'exception',
    showInMenu: false,
    component: LoadableComponent(() => import('../scenes/Exception')),
  },
];

export const routers = [...UserRouter, ...AppRouters];
