import { action } from 'mobx';

import AppConsts from '../lib/appconst';
import tokenAuthService, { TokenAuthService, AuthenticationRequest, ImpersonateRequest } from '../services/tokenAuth';

export interface IAuthenticationStore {
  isAuthenticated: boolean;
  login(loginRequest: AuthenticationRequest): Promise<void>;
  logout(): void;
}

export default class AuthenticationStore implements IAuthenticationStore {
  private tokenAuthService: TokenAuthService;

  constructor(tokenAuthSvc: TokenAuthService = tokenAuthService) {
    this.tokenAuthService = tokenAuthSvc;
  }

  @action
  async backToAccoount(): Promise<void> {
    const result = await this.tokenAuthService.backToAccount();

    abp.auth.setToken(result.accessToken, null);
    abp.utils.setCookieValue(AppConsts.authorization.encrptedAuthTokenName, result.encryptedAccessToken, null, abp.appPath);
  }

  @action
  async impersonate(impersonateRequest: ImpersonateRequest): Promise<void> {
    const result = await this.tokenAuthService.impersonate(impersonateRequest);

    abp.auth.setToken(result.accessToken, null);
    abp.utils.setCookieValue(AppConsts.authorization.encrptedAuthTokenName, result.encryptedAccessToken, null, abp.appPath);
  }

  // eslint-disable-next-line class-methods-use-this
  get isAuthenticated(): boolean {
    if (!abp.session.userId) {
      return false;
    }

    return true;
  }

  @action
  async login(loginRequest: AuthenticationRequest): Promise<void> {
    const { userNameOrEmailAddress, password, rememberClient } = loginRequest;

    const result = await this.tokenAuthService.authenticate({
      userNameOrEmailAddress,
      password,
      rememberClient,
    });

    let tokenExpireDate;
    if (rememberClient) {
      tokenExpireDate = new Date(new Date().getTime() + 1000 * result.expireInSeconds);
    }
    abp.auth.setToken(result.accessToken, tokenExpireDate);

    abp.utils.setCookieValue(AppConsts.authorization.encrptedAuthTokenName, result.encryptedAccessToken, tokenExpireDate, abp.appPath);
  }

  @action
  logout(): void {
    localStorage.clear();
    sessionStorage.clear();
    abp.auth.clearToken();
  }
}
