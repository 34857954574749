import ServiceBase from './core/serviceBase';
import { EntityDto } from './dto/entityDto';
import { PagedFilteredAndSortedResultRequest } from './dto/pagedFilteredAndSortedResultRequest';
import { PagedResultDto } from './dto/pagedResultDto';
import { PropertyDto } from './dto/propertyDto';
import { SummaryDto } from './dto/summaryDto';
import { UpdateDto } from './dto/updateDto';

export type ConfigDto = EntityDto & {
  brand?: SummaryDto;
  lastModificationTime: string;
  manufacturer?: SummaryDto;
  product?: SummaryDto;
  property?: PropertyDto;
  series?: SummaryDto;
};

export type MonitoringDto = EntityDto & {
  configs: Array<ConfigDto>;
  creationTime: string;
  lastModificationTime: string;
  lastRunTime: string;
  lastStatus: string;
  schedule: string;
  url: string;
};

export type UpdateMonitoringConfigDto = ConfigDto & UpdateDto;

export type UpdateMonitoringDto = EntityDto &
  UpdateDto & {
    configs: Array<UpdateMonitoringConfigDto>;
    creationTime: string;
    lastModificationTime: string;
    lastRunTime: string;
    lastStatus: string;
    schedule: string;
    url: string;
  };

export type GetAllMonitoringInput = PagedFilteredAndSortedResultRequest & {
  brandId?: number;
  manufacturerId?: number;
  productId?: number;
  seriesId?: number;
};

export type MonitoringParentKey = 'brandId' | 'manufacturerId' | 'productId' | 'seriesId';

export class MonitoringService extends ServiceBase {
  async delete(params: EntityDto): Promise<void> {
    await this.httpSvc.delete('/api/services/app/Monitoring/Delete', { params });
  }

  async get(params: EntityDto): Promise<MonitoringDto> {
    const result = await this.httpSvc.get('/api/services/app/Monitoring/Get', { params });
    return result.data.result;
  }

  async getAll(params: GetAllMonitoringInput): Promise<PagedResultDto<MonitoringDto>> {
    const result = await this.httpSvc.get('/api/services/app/Monitoring/GetAll', { params });
    return result.data.result;
  }
}

export default new MonitoringService();
