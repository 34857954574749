import './App.css';

import React from 'react';
import { ErrorBoundary, useErrorHandler } from 'react-error-boundary';
import { inject } from 'mobx-react';

import Router from './Navigation';
import SessionContext from './contexts/SessionContext';
import SessionStore from './stores/sessionStore';
import SignalRAspNetCoreHelper from './lib/signalRAspNetCoreHelper';
import { StoreTypes } from './stores/storeInitializer';
import Exception from './scenes/Exception';

type Props = {
  sessionStore?: SessionStore;
};

const App: React.FC<Props> = ({ sessionStore }: Props) => {
  const handleError = useErrorHandler();

  React.useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    sessionStore?.getCurrentLoginInformations().catch(handleError);
  }, [sessionStore]);

  React.useEffect(() => {
    if (
      sessionStore?.currentLogin?.user &&
      sessionStore?.currentLogin?.application?.features &&
      sessionStore?.currentLogin?.application?.features['SignalR'] &&
      sessionStore?.currentLogin?.application?.features['SignalR.AspNetCore']
    ) {
      SignalRAspNetCoreHelper.initSignalR();
    }
  }, [sessionStore?.currentLogin]);

  return (
    <SessionContext.Provider value={abp.session}>
      <ErrorBoundary FallbackComponent={Exception}>
        <Router />
      </ErrorBoundary>
    </SessionContext.Provider>
  );
};

export default inject(StoreTypes.Session)(App);
