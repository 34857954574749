import { action, observable } from 'mobx';

import monitoringService, { GetAllMonitoringInput, MonitoringDto, MonitoringService } from '../services/monitoring';
import { PagedResultDto } from '../services/dto/pagedResultDto';
import { EntityDto } from '../services/dto/entityDto';

export interface IMonitoringStore {
  monitoring: MonitoringDto;
  monitorings: PagedResultDto<MonitoringDto>;

  delete(input: EntityDto): Promise<void>;
  get(input: EntityDto): Promise<void>;
  getAll(input: GetAllMonitoringInput): Promise<void>;
  resetMonitoring(): void;
  resetMonitorings(): void;
}

export default class MonitoringStore implements IMonitoringStore {
  @observable monitoring!: MonitoringDto;

  @observable monitorings!: PagedResultDto<MonitoringDto>;

  private monitoringService: MonitoringService;

  constructor(monitoringSvc: MonitoringService = monitoringService) {
    this.monitoringService = monitoringSvc;
  }

  @action
  async delete(params: EntityDto<number>): Promise<void> {
    await this.monitoringService.delete(params);

    if (this.monitorings) {
      this.monitorings.items = this.monitorings.items.filter((x) => x.id !== params.id);
      this.monitorings.totalCount -= 1;
    }
  }

  @action
  async get(input: EntityDto): Promise<void> {
    const result = await this.monitoringService.get(input);
    this.monitoring = result;
  }

  @action
  async getAll(input: GetAllMonitoringInput): Promise<void> {
    const result = await this.monitoringService.getAll(input);
    this.monitorings = result;
  }

  @action
  resetMonitoring(): void {
    this.monitoring = undefined;
  }

  @action
  resetMonitorings(): void {
    this.monitorings = undefined;
  }
}
