import { action, observable } from 'mobx';

import changeService, { ChangeDto, ChangeService, GetAllChangesInput } from '../services/changes';
import { PagedResultDto } from '../services/dto/pagedResultDto';

export interface IChangeStore {
  changes: PagedResultDto<ChangeDto>;

  getAll(input: GetAllChangesInput): Promise<void>;
  resetChanges(): void;
}

export default class ChangeStore implements IChangeStore {
  @observable changes!: PagedResultDto<ChangeDto>;

  private changeService: ChangeService;

  constructor(changeSvc: ChangeService = changeService) {
    this.changeService = changeSvc;
  }

  @action
  async getAll(input: GetAllChangesInput): Promise<void> {
    const result = await this.changeService.getAll(input);
    this.changes = result;
  }

  @action
  resetChanges(): void {
    this.changes = undefined;
  }
}
