import { action, observable } from 'mobx';

import { EntityDto } from '../services/dto/entityDto';
import { PagedResultDto } from '../services/dto/pagedResultDto';
import { RoleDto } from '../services/role';
import userService, { CreateUserInput, GetAllUsersInput, UpdateUserInput, UserDto, UserService } from '../services/user';

export interface IUserStore {
  roles: RoleDto[];
  user: UserDto;
  users: PagedResultDto<UserDto>;

  changeLanguage(languageName: string): Promise<void>;
  create(input: CreateUserInput): Promise<void>;
  delete(entityDto: EntityDto): Promise<void>;
  get(entityDto: EntityDto): Promise<void>;
  getAll(input: GetAllUsersInput): Promise<void>;
  getRoles(): Promise<void>;
  resetRoles(): void;
  resetUser(): void;
  resetUsers(): void;
  update(input: UpdateUserInput): Promise<void>;
}

export default class UserStore {
  @observable user!: UserDto;

  @observable roles: RoleDto[] = [];

  @observable users!: PagedResultDto<UserDto>;

  private userService: UserService;

  constructor(userSvc: UserService = userService) {
    this.userService = userSvc;
  }

  async changeLanguage(languageName: string): Promise<void> {
    await this.userService.changeLanguage({ languageName });
  }

  @action
  async create(input: CreateUserInput): Promise<void> {
    await this.userService.create(input);
  }

  @action
  async delete(entityDto: EntityDto): Promise<void> {
    await this.userService.delete(entityDto);
    this.users.items = this.users.items.filter((x) => x.id !== entityDto.id);
  }

  @action
  async get(entityDto: EntityDto): Promise<void> {
    const result = await this.userService.get(entityDto);
    this.user = result;
  }

  @action
  async getAll(input: GetAllUsersInput): Promise<void> {
    const result = await this.userService.getAll(input);
    this.users = result;
  }

  @action
  async getRoles(): Promise<void> {
    const result = await this.userService.getRoles();
    this.roles = result;
  }

  @action
  resetRoles(): void {
    this.roles = undefined;
  }

  @action
  resetUser(): void {
    this.user = undefined;
  }

  @action
  resetUsers(): void {
    this.users = undefined;
  }

  @action
  async update(input: UpdateUserInput): Promise<void> {
    const result = await this.userService.update(input);
    this.users.items = this.users.items.map((x) => {
      if (x.id === input.id) {
        return result;
      }

      return x;
    });
  }
}
