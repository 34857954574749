import ServiceBase from './core/serviceBase';

export enum TenantAvailabilityState {
  Available = 1,
  InActive,
  NotFound,
}

export type IsTenantAvaibleResponse = {
  state: TenantAvailabilityState;
  tenantId: number;
};

export type IsTenantAvaibleRequest = {
  tenancyName: string;
};

export type RegisterRequest = {
  name: string;
  surname: string;
  userName: string;
  emailAddress: string;
  password: string;
  captchaResponse: string;
};

export type RegisterResponse = {
  canLogin: boolean;
};

export type RequestPasswordResetRequest = {
  tenantId?: number;
  emailAddress: string;
};

export class AccountService extends ServiceBase {
  async isTenantAvailable(isTenantAvaibleRequest: IsTenantAvaibleRequest): Promise<IsTenantAvaibleResponse> {
    const result = await this.httpSvc.post('api/services/app/Account/IsTenantAvailable', isTenantAvaibleRequest);
    return result.data.result;
  }

  async register(registerRequest: RegisterRequest): Promise<RegisterResponse> {
    const result = await this.httpSvc.post('api/services/app/Account/Register', registerRequest);
    return result.data.result;
  }

  async requestPasswordReset(requestPasswordReset: RequestPasswordResetRequest): Promise<void> {
    await this.httpSvc.post('api/services/app/Account/RequestPasswordReset', requestPasswordReset);
  }
}

export default new AccountService();
