import { action, observable } from 'mobx';

import { EntityDto } from '../services/dto/entityDto';
import { PagedResultDto } from '../services/dto/pagedResultDto';
import { SeriesDto } from '../services/series';
import seriesLibraryService, { SeriesLibraryService, GetAllSeriesLibrariesInput, UpdateSeriesLibraryInput } from '../services/seriesLibrary';

export interface ISeriesLibraryStore {
  allSeries: PagedResultDto<SeriesDto>;
  series: SeriesDto;

  delete(input: EntityDto): Promise<void>;
  get(input: EntityDto): Promise<void>;
  getAll(input: GetAllSeriesLibrariesInput): Promise<void>;
  post(input: EntityDto): Promise<SeriesDto>;
  put(input: UpdateSeriesLibraryInput): Promise<void>;
  resetAllSeries(): void;
  resetSeries(): void;
}

export default class SeriesLibraryStore implements ISeriesLibraryStore {
  @observable allSeries!: PagedResultDto<SeriesDto>;

  @observable series!: SeriesDto;

  private seriesLibraryService: SeriesLibraryService;

  constructor(seriesLibrarySvc: SeriesLibraryService = seriesLibraryService) {
    this.seriesLibraryService = seriesLibrarySvc;
  }

  @action
  async delete(params: EntityDto<number>): Promise<void> {
    await this.seriesLibraryService.delete(params);

    if (this.allSeries) {
      this.allSeries.items = this.allSeries.items.filter((x) => x.id !== params.id);
      this.allSeries.totalCount -= 1;
    }
  }

  @action
  async get(input: EntityDto): Promise<void> {
    const result = await this.seriesLibraryService.get(input);
    this.series = result;
  }

  @action
  async getAll(input: GetAllSeriesLibrariesInput): Promise<void> {
    const result = await this.seriesLibraryService.getAll(input);
    this.allSeries = result;
  }

  post(input: EntityDto): Promise<SeriesDto> {
    return this.seriesLibraryService.post(input);
  }

  @action
  async put(input: UpdateSeriesLibraryInput): Promise<void> {
    const result = await this.seriesLibraryService.put(input);
    this.series = result;
  }

  @action
  resetAllSeries(): void {
    this.allSeries = undefined;
  }

  @action
  resetSeries(): void {
    this.series = undefined;
  }
}
