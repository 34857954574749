import { action, observable } from 'mobx';
import { ProductDto } from '../services/product';
import productLibraryService, { GetAllProductLibrariesInput, ProductLibraryService, UpdateProductLibraryInput } from '../services/productLibrary';
import { EntityDto } from '../services/dto/entityDto';

import { PagedFilteredAndSortedProductResponse } from '../services/dto/pagedFilteredAndSortedProductResponse';

export interface IProductLibraryStore {
  product: ProductDto;
  products: PagedFilteredAndSortedProductResponse<ProductDto>;

  delete(input: EntityDto): Promise<void>;
  get(input: EntityDto): Promise<void>;
  getAll(input: GetAllProductLibrariesInput): Promise<void>;
  post(input: EntityDto): Promise<ProductDto>;
  put(input: UpdateProductLibraryInput): Promise<void>;
  resetProduct(): void;
  resetProducts(): void;
}

export default class ProductLibraryStore implements IProductLibraryStore {
  @observable product!: ProductDto;

  @observable products!: PagedFilteredAndSortedProductResponse<ProductDto>;

  private productLibraryService: ProductLibraryService;

  constructor(productLibrarySvc: ProductLibraryService = productLibraryService) {
    this.productLibraryService = productLibrarySvc;
  }

  @action
  async delete(params: EntityDto<number>): Promise<void> {
    await this.productLibraryService.delete(params);

    if (this.products) {
      this.products.items = this.products.items.filter((x) => x.id !== params.id);
      this.products.totalCount -= 1;
    }
  }

  @action
  async get(input: EntityDto): Promise<void> {
    const result = await this.productLibraryService.get(input);
    this.product = result;
  }

  @action
  async getAll(input: GetAllProductLibrariesInput): Promise<void> {
    const result = await this.productLibraryService.getAll(input);
    this.products = result;
  }

  async post(input: EntityDto): Promise<ProductDto> {
    return this.productLibraryService.post(input);
  }

  @action
  async put(input: UpdateProductLibraryInput): Promise<void> {
    const result = await this.productLibraryService.put(input);
    this.product = result;
  }

  @action
  resetProduct(): void {
    this.product = undefined;
  }

  @action
  resetProducts(): void {
    this.products = undefined;
  }
}
