import ServiceBase from './core/serviceBase';
import { EditPropertyDto } from './dto/editPropertyDto';
import { EntityDto } from './dto/entityDto';
import { PagedFilteredAndSortedResultRequest } from './dto/pagedFilteredAndSortedResultRequest';
import { PagedResultDto } from './dto/pagedResultDto';
import { PropertyDto } from './dto/propertyDto';
import { UpdatePropertyDto } from './dto/updatePropertyDto';
import { UpdateMonitoringDto } from './monitoring';

export type ManufacturerDto = EntityDto & {
  creationTime: string;
  description: string;
  imageUrl: string;
  lastModificationTime: string;
  name: string;
  properties: Array<PropertyDto>;
  url?: string;
};

export type ManufacturerPropertyDto = PropertyDto & {
  manufacturerId: number;
};

export type EditManufacturerPropertyDto = EditPropertyDto & {
  manufacturerId: number;
};

export type UpdateManufacturerPropertyDto = UpdatePropertyDto & {
  manufacturerId: number;
};

export type UpdateManufacturerInput = EntityDto & {
  description: string;
  imageUrl: string;
  monitoring?: Array<UpdateMonitoringDto>;
  name: string;
  properties: Array<UpdatePropertyDto>;
  url: string;
};

export class ManufacturerService extends ServiceBase {
  async delete(params: EntityDto): Promise<void> {
    await this.httpSvc.delete('/api/services/app/Manufacturer/Delete', { params });
  }

  async get(params: EntityDto): Promise<ManufacturerDto> {
    const result = await this.httpSvc.get('/api/services/app/Manufacturer/Get', { params });
    return result.data.result;
  }

  async getAll(params: PagedFilteredAndSortedResultRequest): Promise<PagedResultDto<ManufacturerDto>> {
    const result = await this.httpSvc.get('/api/services/app/Manufacturer/GetAll', { params });
    return result.data.result;
  }

  async put(input: UpdateManufacturerInput): Promise<ManufacturerDto> {
    const result = await this.httpSvc.put('/api/services/app/Manufacturer/Update', input);
    return result.data.result;
  }
}

export default new ManufacturerService();
