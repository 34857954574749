import ServiceBase from './core/serviceBase';
import { EditPropertyDto } from './dto/editPropertyDto';
import { EntityDto } from './dto/entityDto';
import { PagedFilteredAndSortedProductResponse } from './dto/pagedFilteredAndSortedProductResponse';
import { PagedFilteredAndSortedResultRequest } from './dto/pagedFilteredAndSortedResultRequest';
import { PropertyDto } from './dto/propertyDto';
import { UpdatePropertyDto } from './dto/updatePropertyDto';
import { ManufacturerDto } from './manufacturer';

export type CompetitorPropertyDto = PropertyDto & {
  competitorId: number;
};

export type EditCompetitorPropertyDto = EditPropertyDto & {
  competitorId: number;
};

export type UpdateCompetitorPropertyDto = UpdatePropertyDto & {
  competitorId: number;
};

export type UpdateCompetitorInput = EntityDto & {
  properties: Array<UpdatePropertyDto>;
};

export class CompetitorService extends ServiceBase {
  async delete(params: EntityDto): Promise<void> {
    await this.httpSvc.delete('/api/services/app/Competitor/Delete', { params });
  }

  async get(params: EntityDto): Promise<ManufacturerDto> {
    const result = await this.httpSvc.get('api/services/app/Competitor/Get', { params });
    return result.data.result;
  }

  async getAll(params: PagedFilteredAndSortedResultRequest): Promise<PagedFilteredAndSortedProductResponse<ManufacturerDto>> {
    const result = await this.httpSvc.get('api/services/app/Competitor/GetAll', { params });
    return result.data.result;
  }

  async post(input: EntityDto): Promise<ManufacturerDto> {
    const result = await this.httpSvc.post('/api/services/app/Competitor/Create', input);
    return result.data.result;
  }

  async put(input: UpdateCompetitorInput): Promise<ManufacturerDto> {
    const result = await this.httpSvc.put('/api/services/app/Competitor/Update', input);
    return result.data.result;
  }
}

export default new CompetitorService();
