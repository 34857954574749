import { action, observable } from 'mobx';

import comparisonService, {
  ComparisonService,
  ComparisonDto,
  UpdateComparisonInput,
  CreateComparisonInput,
  GetAllComparisonsOutput,
  GetAllComparisonsInput,
} from '../services/comparison';
import { EntityDto } from '../services/dto/entityDto';

export type UpdateComparisonLineItemOptions = { hasComments?: boolean; hasWarnings?: boolean };

export interface IComparisonStore {
  comparison: ComparisonDto;
  comparisons: GetAllComparisonsOutput;

  delete(entityDto: EntityDto): Promise<void>;
  get(entityDto: EntityDto): Promise<void>;
  getAll(input: GetAllComparisonsInput): Promise<void>;
  post(input: CreateComparisonInput): Promise<ComparisonDto>;
  put(input: UpdateComparisonInput): Promise<void>;
  resetComparison(): void;
  resetComparisons(): void;
  updateComparisonLineItem(comparisonLineItemId: number, opts: UpdateComparisonLineItemOptions): void;
}

export default class ComparisonStore implements IComparisonStore {
  @observable comparison: ComparisonDto;

  @observable comparisons: GetAllComparisonsOutput;

  private comparisonService: ComparisonService;

  constructor(comparisonSvc: ComparisonService = comparisonService) {
    this.comparisonService = comparisonSvc;
  }

  async delete(entityDto: EntityDto): Promise<void> {
    await this.comparisonService.delete(entityDto);
  }

  @action
  async get(entityDto: EntityDto): Promise<void> {
    const result = await this.comparisonService.get(entityDto);
    this.comparison = result;
  }

  @action
  async getAll(input: GetAllComparisonsInput): Promise<void> {
    const result = await this.comparisonService.getAll(input);
    this.comparisons = result;
  }

  post(input: CreateComparisonInput): Promise<ComparisonDto> {
    return this.comparisonService.post(input);
  }

  async put(input: UpdateComparisonInput): Promise<void> {
    await this.comparisonService.put(input);
  }

  @action
  resetComparison(): void {
    this.comparison = undefined;
  }

  @action
  resetComparisons(): void {
    this.comparisons = undefined;
  }

  @action
  updateComparisonLineItem(comparisonLineItemId: number, opts: UpdateComparisonLineItemOptions): void {
    if (this.comparison) {
      const index = this.comparison.lineItems.findIndex(({ id }) => id === comparisonLineItemId);
      if (index === -1) {
        throw new Error(`Could not locate the line item with ID ${comparisonLineItemId}`);
      }

      const lineItem = this.comparison.lineItems[index];

      if (typeof opts.hasComments !== 'undefined') {
        lineItem.hasComments = opts.hasComments;
      }

      if (typeof opts.hasWarnings !== 'undefined') {
        lineItem.hasWarnings = opts.hasWarnings;
      }

      this.comparison = {
        ...this.comparison,
        lineItems: [...this.comparison.lineItems.slice(0, index), lineItem, ...this.comparison.lineItems.slice(index + 1)],
      };
    }
  }
}
