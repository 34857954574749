import ServiceBase from './core/serviceBase';
import { EditPropertyDto } from './dto/editPropertyDto';
import { EntityDto } from './dto/entityDto';
import { PagedFilteredAndSortedResultRequest } from './dto/pagedFilteredAndSortedResultRequest';
import { PagedResultDto } from './dto/pagedResultDto';
import { PropertyDto } from './dto/propertyDto';
import { UpdatePropertyDto } from './dto/updatePropertyDto';
import { SeriesDto } from './series';

export type SeriesLibraryPropertyDto = PropertyDto & {
  seriesLibraryId: number;
};

export type EditSeriesLibraryPropertyDto = EditPropertyDto & {
  seriesLibraryId: number;
};

export type UpdateSeriesLibraryPropertyDto = UpdatePropertyDto & {
  seriesLibraryId: number;
};

export type UpdateSeriesLibraryInput = EntityDto & {
  properties: Array<UpdatePropertyDto>;
};

export type GetAllSeriesLibrariesInput = PagedFilteredAndSortedResultRequest & {
  brandLibraryId?: number;
};

export class SeriesLibraryService extends ServiceBase {
  async delete(params: EntityDto): Promise<void> {
    await this.httpSvc.delete('/api/services/app/SeriesLibrary/Delete', { params });
  }

  async get(params: EntityDto): Promise<SeriesDto> {
    const result = await this.httpSvc.get('/api/services/app/SeriesLibrary/Get', { params });
    return result.data.result;
  }

  async getAll(params: GetAllSeriesLibrariesInput): Promise<PagedResultDto<SeriesDto>> {
    const result = await this.httpSvc.get('/api/services/app/SeriesLibrary/GetAll', { params });
    return result.data.result;
  }

  async post(input: EntityDto): Promise<SeriesDto> {
    const result = await this.httpSvc.post('/api/services/app/SeriesLibrary/Create', input);
    return result.data.result;
  }

  async put(input: UpdateSeriesLibraryInput): Promise<SeriesDto> {
    const result = await this.httpSvc.put('/api/services/app/SeriesLibrary/Update', input);
    return result.data.result;
  }
}

export default new SeriesLibraryService();
