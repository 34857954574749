import AccountStore, { IAccountStore } from './accountStore';
import AuthenticationStore, { IAuthenticationStore } from './authenticationStore';
import BrandLibraryStore, { IBrandLibraryStore } from './brandLibraryStore';
import BrandStore, { IBrandStore } from './brandStore';
import ChangeStore, { IChangeStore } from './changeStore';
import CommentStore, { ICommentStore } from './commentStore';
import ComparisonStore, { IComparisonStore } from './comparisonStore';
import CompetitorProductStore, { ICompetitorProductStore } from './competitorProductStore';
import CompetitorStore, { ICompetitorStore } from './competitorStore';
import ManufacturerLibraryStore, { IManufacturerLibraryStore } from './manufacturerLibraryStore';
import ManufacturerStore, { IManufacturerStore } from './manufacturerStore';
import MonitoringStore, { IMonitoringStore } from './monitoringStore';
import ProductLibraryStore, { IProductLibraryStore } from './productLibraryStore';
import ProductStore, { IProductStore } from './productStore';
import ProfileStore, { IProfileStore } from './profileStore';
import RoleStore, { IRoleStore } from './roleStore';
import SeriesLibraryStore, { ISeriesLibraryStore } from './seriesLibraryStore';
import SeriesStore, { ISeriesStore } from './seriesStore';
import SessionStore, { ISessionStore } from './sessionStore';
import SnapshotStore, { ISnapshotStore } from './snapshotStore';
import TenantStore, { ITenantStore } from './tenantStore';
import UserStore, { IUserStore } from './userStore';
import WarningStore, { IWarningStore } from './warningStore';

export type Stores = {
  accountStore: IAccountStore;
  authenticationStore: IAuthenticationStore;
  brandLibraryStore: IBrandLibraryStore;
  brandStore: IBrandStore;
  changeStore: IChangeStore;
  commentStore: ICommentStore;
  comparisonStore: IComparisonStore;
  competitorProductStore: ICompetitorProductStore;
  competitorStore: ICompetitorStore;
  manufacturerLibraryStore: IManufacturerLibraryStore;
  manufacturerStore: IManufacturerStore;
  monitoringStore: IMonitoringStore;
  productLibraryStore: IProductLibraryStore;
  productStore: IProductStore;
  profileStore: IProfileStore;
  roleStore: IRoleStore;
  seriesLibraryStore: ISeriesLibraryStore;
  seriesStore: ISeriesStore;
  sessionStore: ISessionStore;
  snapshotStore: ISnapshotStore;
  tenantStore: ITenantStore;
  userStore: IUserStore;
  warningStore: IWarningStore;
};

export enum StoreTypes {
  Account = 'accountStore',
  Authentication = 'authenticationStore',
  BrandLibrary = 'brandLibraryStore',
  Brand = 'brandStore',
  Change = 'changeStore',
  Comment = 'commentStore',
  Comparison = 'comparisonStore',
  CompetitorProduct = 'competitorProductStore',
  Competitor = 'competitorStore',
  ManufacturerLibrary = 'manufacturerLibraryStore',
  Manufacturer = 'manufacturerStore',
  Monitoring = 'monitoringStore',
  ProductLibrary = 'productLibraryStore',
  Product = 'productStore',
  Profile = 'profileStore',
  Role = 'roleStore',
  SeriesLibrary = 'seriesLibraryStore',
  Series = 'seriesStore',
  Session = 'sessionStore',
  Snapshot = 'snapshotStore',
  Tenant = 'tenantStore',
  User = 'userStore',
  Warning = 'warningStore',
}

export default function initializeStores(): Stores {
  return {
    accountStore: new AccountStore(),
    authenticationStore: new AuthenticationStore(),
    brandLibraryStore: new BrandLibraryStore(),
    brandStore: new BrandStore(),
    changeStore: new ChangeStore(),
    commentStore: new CommentStore(),
    comparisonStore: new ComparisonStore(),
    competitorProductStore: new CompetitorProductStore(),
    competitorStore: new CompetitorStore(),
    manufacturerLibraryStore: new ManufacturerLibraryStore(),
    manufacturerStore: new ManufacturerStore(),
    monitoringStore: new MonitoringStore(),
    productLibraryStore: new ProductLibraryStore(),
    productStore: new ProductStore(),
    profileStore: new ProfileStore(),
    roleStore: new RoleStore(),
    seriesLibraryStore: new SeriesLibraryStore(),
    seriesStore: new SeriesStore(),
    sessionStore: new SessionStore(),
    snapshotStore: new SnapshotStore(),
    tenantStore: new TenantStore(),
    userStore: new UserStore(),
    warningStore: new WarningStore(),
  };
}
