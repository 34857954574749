import ServiceBase from './core/serviceBase';
import { EntityDto } from './dto/entityDto';
import { PagedResultDto } from './dto/pagedResultDto';
import { PagedAndSortedRequest } from './dto/pagedAndSortedRequest';

export type CreateTenantRequest = {
  adminEmailAddress: string;
  connectionString: string;
  isActive: boolean;
  name: string;
  tenancyName: string;
};

export type GetAllTenantsInput = PagedAndSortedRequest & {
  keyword: string;
};

export type TenantDto = EntityDto & {
  adminId: number;
  isActive: boolean;
  name: string;
  tenancyName: string;
};

export class TenantService extends ServiceBase {
  async create(input: CreateTenantRequest): Promise<TenantDto> {
    const result = await this.httpSvc.post('api/services/app/Tenant/Create', input);
    return result.data.result;
  }

  async delete(entityDto: EntityDto): Promise<void> {
    await this.httpSvc.delete('api/services/app/Tenant/Delete', { params: entityDto });
  }

  async get(entityDto: EntityDto): Promise<TenantDto> {
    const result = await this.httpSvc.get('api/services/app/Tenant/Get', { params: entityDto });
    return result.data.result;
  }

  async getAll(input: GetAllTenantsInput): Promise<PagedResultDto<TenantDto>> {
    const result = await this.httpSvc.get('api/services/app/Tenant/GetAll', { params: input });
    return result.data.result;
  }

  async update(entityDto: TenantDto): Promise<TenantDto> {
    const result = await this.httpSvc.put('api/services/app/Tenant/Update', entityDto);
    return result.data.result;
  }
}

export default new TenantService();
