import ServiceBase from './core/serviceBase';
import { EntityDto } from './dto/entityDto';
import { PagedAndSortedRequest } from './dto/pagedAndSortedRequest';
import { PagedResultDto } from './dto/pagedResultDto';

export type SnapshotDto = EntityDto & {
  contentType: string;
  creationTime: string;
  imageUrl: string;
  fileName: string;
  pageUrl: string;
};

export type SnapshotParentKey =
  | 'brandId'
  | 'brandLibraryId'
  | 'competitorId'
  | 'competitorProductId'
  | 'monitoringId'
  | 'manufacturerId'
  | 'manufacturerLibraryId'
  | 'productId'
  | 'productLibraryId'
  | 'seriesId'
  | 'seriesLibraryId';

export type GetAllSnapshotsInput = PagedAndSortedRequest & {
  brandId?: number;
  brandLibraryId?: number;
  competitorId?: number;
  competitorProductId?: number;
  monitoringId?: number;
  manufacturerId?: number;
  manufacturerLibraryId?: number;
  productId?: number;
  productLibraryId?: number;
  seriesId?: number;
  seriesLibraryId?: number;
};

export class SnapshotService extends ServiceBase {
  async getAll(params: GetAllSnapshotsInput): Promise<PagedResultDto<SnapshotDto>> {
    const result = await this.httpSvc.get('/api/services/app/Snapshot/GetAll', { params });
    return result.data.result;
  }
}

export default new SnapshotService();
