import './index.less';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'mobx-react-lite/batchingForReactDom';

import { Provider } from 'mobx-react';
import moment from 'moment';
import { observable } from 'mobx';
import * as MomentTimezone from 'moment-timezone';
import React from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import CacheBuster from './components/CacheBuster';
import StoreContext from './contexts/StoreContext';
import Utils from './utils/utils';
import { Exception } from './scenes/Exception';
import abpUserConfigurationService from './services/abpUserConfigurationService';
import initializeStores from './stores/storeInitializer';
import bugsnag from './lib/bugsnag';

Utils.setLocalization();

bugsnag.config();

abpUserConfigurationService
  .getAll()
  .then((data) => {
    Utils.extend(true, abp, data.data.result);
    abp.clock.provider = Utils.getCurrentClockProvider(data.data.result.clock.provider);

    moment.locale(abp.localization.currentLanguage.name);

    if (abp.clock.provider.supportsMultipleTimezone) {
      MomentTimezone.tz.setDefault(abp.timing.timeZoneInfo.iana.timeZoneId);
    }

    const stores = observable(initializeStores());

    ReactDOM.render(
      <CacheBuster version={process.env.PACKAGE_VERSION}>
        <StoreContext.Provider value={stores}>
          <Provider {...stores}>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </Provider>
        </StoreContext.Provider>
      </CacheBuster>,
      document.getElementById('root') as HTMLElement
    );
  })
  .catch((err) => {
    bugsnag.notify(err as Error);

    ReactDOM.render(
      <Exception error={err} history={undefined} location={undefined} match={undefined} resetErrorBoundary={undefined} />,
      document.getElementById('root') as HTMLElement
    );
  });
