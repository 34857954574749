import { action, observable } from 'mobx';

import manufacturerService, { ManufacturerDto, ManufacturerService, UpdateManufacturerInput } from '../services/manufacturer';
import { PagedFilteredAndSortedResultRequest } from '../services/dto/pagedFilteredAndSortedResultRequest';
import { PagedResultDto } from '../services/dto/pagedResultDto';
import { EntityDto } from '../services/dto/entityDto';

export interface IManufacturerStore {
  manufacturer: ManufacturerDto;
  manufacturers: PagedResultDto<ManufacturerDto>;

  delete(input: EntityDto): Promise<void>;
  get(input: EntityDto): Promise<void>;
  getAll(input: PagedFilteredAndSortedResultRequest): Promise<void>;
  put(input: UpdateManufacturerInput): Promise<void>;
  resetManufacturer(): void;
  resetManufacturers(): void;
}

export default class ManufacturerStore implements IManufacturerStore {
  @observable manufacturer!: ManufacturerDto;

  @observable manufacturers!: PagedResultDto<ManufacturerDto>;

  private manufacturerService: ManufacturerService;

  constructor(manufacturerSvc: ManufacturerService = manufacturerService) {
    this.manufacturerService = manufacturerSvc;
  }

  @action
  async delete(params: EntityDto<number>): Promise<void> {
    await this.manufacturerService.delete(params);

    if (this.manufacturers) {
      this.manufacturers.items = this.manufacturers.items.filter((x) => x.id !== params.id);
      this.manufacturers.totalCount -= 1;
    }
  }

  @action
  async get(input: EntityDto): Promise<void> {
    const result = await this.manufacturerService.get(input);
    this.manufacturer = result;
  }

  @action
  async getAll(input: PagedFilteredAndSortedResultRequest): Promise<void> {
    const result = await this.manufacturerService.getAll(input);
    this.manufacturers = result;
  }

  @action
  async put(input: UpdateManufacturerInput): Promise<void> {
    const result = await this.manufacturerService.put(input);
    this.manufacturer = result;
  }

  @action
  resetManufacturer(): void {
    this.manufacturer = undefined;
  }

  @action
  resetManufacturers(): void {
    this.manufacturers = undefined;
  }
}
