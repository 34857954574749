import AppConsts from './appconst';

export function L(key: string, sourceName?: string, ...args: Array<string>): string {
  const localizationSourceName = AppConsts.localization.defaultLocalizationSourceName;
  return abp.localization.localize(key, sourceName || localizationSourceName, ...args);
}

export function isGranted(permissionName: string): boolean {
  return abp.auth.isGranted(permissionName);
}
