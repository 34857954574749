import { AxiosInstance } from 'axios';
import http from './httpService';

export default class ServiceBase {
  protected httpSvc: AxiosInstance;

  constructor(httpSvc?: AxiosInstance) {
    this.httpSvc = httpSvc || http;
  }
}
