import ServiceBase from './core/serviceBase';
import { EditPropertyDto } from './dto/editPropertyDto';
import { EntityDto } from './dto/entityDto';
import { PagedFilteredAndSortedResultRequest } from './dto/pagedFilteredAndSortedResultRequest';
import { PagedResultDto } from './dto/pagedResultDto';
import { PropertyDto } from './dto/propertyDto';
import { SummaryDto } from './dto/summaryDto';
import { UpdatePropertyDto } from './dto/updatePropertyDto';
import { UpdateMonitoringDto } from './monitoring';

export type SeriesDto = EntityDto & {
  brand: SummaryDto;
  creationTime: string;
  description: string;
  imageUrl: string;
  lastModificationTime: string;
  manufacturer: SummaryDto;
  name: string;
  properties: Array<PropertyDto>;
  url?: string;
};

export type SeriesPropertyDto = PropertyDto & {
  seriesId: number;
};

export type EditSeriesPropertyDto = EditPropertyDto & {
  seriesId: number;
};

export type UpdateSeriesPropertyDto = UpdatePropertyDto & {
  seriesId: number;
};

export type UpdateSeriesInput = EntityDto & {
  brandId: number;
  description: string;
  imageUrl: string;
  monitoring?: Array<UpdateMonitoringDto>;
  name: string;
  properties: Array<UpdatePropertyDto>;
  url: string;
};

export type GetAllSeriesInput = PagedFilteredAndSortedResultRequest & {
  brandId?: number;
};

export class SeriesService extends ServiceBase {
  async delete(params: EntityDto): Promise<void> {
    await this.httpSvc.delete('/api/services/app/Series/Delete', { params });
  }

  async get(params: EntityDto): Promise<SeriesDto> {
    const result = await this.httpSvc.get('/api/services/app/Series/Get', { params });
    return result.data.result;
  }

  async getAll(params: GetAllSeriesInput): Promise<PagedResultDto<SeriesDto>> {
    const result = await this.httpSvc.get('/api/services/app/Series/GetAll', { params });
    return result.data.result;
  }

  async put(input: UpdateSeriesInput): Promise<SeriesDto> {
    const result = await this.httpSvc.put('/api/services/app/Series/Update', input);
    return result.data.result;
  }
}

export default new SeriesService();
