import ServiceBase from './core/serviceBase';
import { EditPropertyDto } from './dto/editPropertyDto';
import { EntityDto } from './dto/entityDto';
import { PagedFilteredAndSortedProductRequest } from './dto/pagedFilteredAndSortedProductRequest';
import { PagedFilteredAndSortedProductResponse } from './dto/pagedFilteredAndSortedProductResponse';
import { PropertyDto } from './dto/propertyDto';
import { UpdatePropertyDto } from './dto/updatePropertyDto';
import { ProductDto } from './product';

export type ProductLibraryPropertyDto = PropertyDto & {
  productLibraryId: number;
};

export type EditProductLibraryPropertyDto = EditPropertyDto & {
  productLibraryId: number;
};

export type UpdateProductLibraryPropertyDto = UpdatePropertyDto & {
  productLibraryId: number;
};

export type UpdateProductLibraryInput = EntityDto & {
  properties: Array<UpdatePropertyDto>;
};

export type GetAllProductLibrariesInput = PagedFilteredAndSortedProductRequest & {
  name?: string;
  seriesLibraryId?: number;
};

export class ProductLibraryService extends ServiceBase {
  async delete(params: EntityDto): Promise<void> {
    await this.httpSvc.delete('/api/services/app/ProductLibrary/Delete', { params });
  }

  async get(params: EntityDto): Promise<ProductDto> {
    const result = await this.httpSvc.get('api/services/app/ProductLibrary/Get', { params });
    return result.data.result;
  }

  async getAll(params: GetAllProductLibrariesInput): Promise<PagedFilteredAndSortedProductResponse<ProductDto>> {
    const result = await this.httpSvc.get('api/services/app/ProductLibrary/GetAll', { params });
    return result.data.result;
  }

  async post(input: EntityDto): Promise<ProductDto> {
    const result = await this.httpSvc.post('/api/services/app/ProductLibrary/Create', input);
    return result.data.result;
  }

  async put(input: UpdateProductLibraryInput): Promise<ProductDto> {
    const result = await this.httpSvc.put('/api/services/app/ProductLibrary/Update', input);
    return result.data.result;
  }
}

export default new ProductLibraryService();
