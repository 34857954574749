import React from 'react';
import { Route, Switch, RouteProps } from 'react-router-dom';

import utils from '../utils/utils';

const Router: React.FC = () => {
  const UserLayout = utils.getRoute('/user').component;
  const AppLayout = utils.getRoute('/').component;

  return (
    <Switch>
      <Route path="/user" render={(props: RouteProps) => <UserLayout {...props} />} />
      <Route path="/" render={(props: RouteProps) => <AppLayout {...props} exact />} />
    </Switch>
  );
};

export default Router;
