import './index.less';

import { Event } from '@bugsnag/js';
import { Avatar, Button, Col, Row } from 'antd';
import React from 'react';
import { FallbackProps } from 'react-error-boundary';
import { RouteComponentProps, withRouter } from 'react-router';

import error401 from '../../images/401.png';
import error404 from '../../images/404.png';
import error500 from '../../images/500.png';
import { L } from '../../lib/abpUtility';
import bugsnag from '../../lib/bugsnag';

const exceptions = [
  { errorCode: '404', errorImg: error404, errorDescription: 'Sorry, the page you visited does not exist' },
  {
    errorCode: '401',
    errorImg: error401,
    errorDescription: 'Sorry, you dont have access to this page',
  },
  { errorCode: '500', errorImg: error500, errorDescription: 'Sorry, the server is reporting an error' },
];

const formatComponentStack = (str: string) => {
  const lines = str.split(/\s*\n\s*/g);
  let ret = '';

  // eslint-disable-next-line no-plusplus
  for (let line = 0, len = lines.length; line < len; line++) {
    if (lines[line].length) ret += `${ret.length ? '\n' : ''}${lines[line]}`;
  }

  return ret;
};

type Props = RouteComponentProps & FallbackProps;

export const Exception: React.FC<Props> = ({ error, history, resetErrorBoundary }: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const code = (error as any)?.response?.status || error?.message;

  // eslint-disable-next-line eqeqeq
  let exception = exceptions.find(({ errorCode }) => errorCode == code);
  if (!exception) {
    // eslint-disable-next-line prefer-destructuring
    exception = exceptions[2];
  }

  if (exception && exception.errorCode === '500') {
    const handledState = { severity: 'error', unhandled: true, severityReason: { type: 'unhandledException' } };
    const event = Event.create(error, true, handledState, null, 0);
    event.addMetadata('react', { componentStack: formatComponentStack(error.stack) });

    bugsnag.notify(event);
  }

  const onBackHome = () => {
    history.push('/');
    resetErrorBoundary();
  };

  React.useEffect(() =>
    history?.listen((_, action) => {
      if (action === 'POP') {
        resetErrorBoundary();
      }
    })
  );

  return (
    <Row style={{ marginTop: 150 }}>
      <Col
        xs={{ span: 7, offset: 1 }}
        sm={{ span: 7, offset: 1 }}
        md={{ span: 7, offset: 1 }}
        lg={{ span: 10, offset: 4 }}
        xl={{ span: 10, offset: 4 }}
        xxl={{ span: 10, offset: 4 }}
      >
        <Avatar shape="square" className="errorAvatar" src={exception.errorImg} />
      </Col>
      <Col
        xs={{ span: 7, offset: 1 }}
        sm={{ span: 7, offset: 1 }}
        md={{ span: 7, offset: 1 }}
        lg={{ span: 5, offset: 1 }}
        xl={{ span: 5, offset: 1 }}
        xxl={{ span: 5, offset: 1 }}
        style={{ marginTop: 75 }}
      >
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          md={{ span: 24, offset: 0 }}
          lg={{ span: 24, offset: 0 }}
          xl={{ span: 24, offset: 0 }}
          xxl={{ span: 24, offset: 0 }}
        >
          <h1 className="errorTitle">{exception.errorCode}</h1>
        </Col>
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          md={{ span: 24, offset: 0 }}
          lg={{ span: 24, offset: 0 }}
          xl={{ span: 24, offset: 0 }}
          xxl={{ span: 24, offset: 0 }}
        >
          <h5 className="errorDescription">{exception.errorDescription}</h5>
        </Col>
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          md={{ span: 24, offset: 0 }}
          lg={{ span: 24, offset: 0 }}
          xl={{ span: 24, offset: 0 }}
          xxl={{ span: 24, offset: 0 }}
        >
          <Button onClick={onBackHome} type="primary">
            {L('BackHome')}
          </Button>
        </Col>
      </Col>
      <Col />
    </Row>
  );
};

export default withRouter(Exception);
