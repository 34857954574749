import { action, observable } from 'mobx';

import profileService, { ChangePasswordRequest, ResetPasswordRequest, ProfileResponse, ProfileService } from '../services/profile';

export interface IProfileStore {
  profile: ProfileResponse;

  changePassword(changePasswordRequest: ChangePasswordRequest): Promise<void>;
  get(): Promise<void>;
  resetPassword(resetPasswordRequest: ResetPasswordRequest): Promise<void>;
  update(profileResponse: ProfileResponse): Promise<void>;
}

export default class UserStore implements IProfileStore {
  @observable profile!: ProfileResponse;

  private profileService: ProfileService;

  constructor(profileSvc: ProfileService = profileService) {
    this.profileService = profileSvc;
  }

  @action
  async changePassword(changePasswordRequest: ChangePasswordRequest): Promise<void> {
    await this.profileService.changePassword(changePasswordRequest);
  }

  @action
  async get(): Promise<void> {
    const result = await this.profileService.get();
    this.profile = result;
  }

  @action
  async resetPassword(resetPasswordRequest: ResetPasswordRequest): Promise<void> {
    await this.profileService.resetPassword(resetPasswordRequest);
  }

  @action
  async update(profileResponse: ProfileResponse): Promise<void> {
    const result = await this.profileService.update(profileResponse);

    abp.utils.setCookieValue(
      'Abp.Localization.CultureName',
      profileResponse.language,
      new Date(new Date().getTime() + 5 * 365 * 86400000), // 5 year
      abp.appPath
    );

    this.profile = result;
  }
}
