import ServiceBase from './core/serviceBase';
import { EditPropertyDto } from './dto/editPropertyDto';
import { EntityDto } from './dto/entityDto';
import { PagedFilteredAndSortedProductRequest } from './dto/pagedFilteredAndSortedProductRequest';
import { PagedFilteredAndSortedProductResponse } from './dto/pagedFilteredAndSortedProductResponse';
import { PropertyDto } from './dto/propertyDto';
import { SummaryDto } from './dto/summaryDto';
import { UpdatePropertyDto } from './dto/updatePropertyDto';
import { UpdateMonitoringDto } from './monitoring';

export type ProductDto = EntityDto & {
  applicationTags: Array<string>;
  brand: SummaryDto;
  creationTime: string;
  description: string;
  imageUrl: string;
  lastModificationTime: string;
  manufacturer: SummaryDto;
  name: string;
  productTags: Array<string>;
  properties: Array<PropertyDto>;
  series: SummaryDto;
  url?: string;
};

export type ProductPropertyDto = PropertyDto & {
  productId: number;
};

export type EditProductPropertyDto = EditPropertyDto & {
  productId: number;
};

export type UpdateProductPropertyDto = UpdatePropertyDto & {
  productId: number;
};

export type GetAllProductsInput = PagedFilteredAndSortedProductRequest & {
  name?: string;
  seriesId?: number;
};

export type UpdateProductInput = EntityDto & {
  description: string;
  imageUrl: string;
  monitoring?: Array<UpdateMonitoringDto>;
  name: string;
  properties: Array<UpdatePropertyDto>;
  seriesId: number;
  url: string;
};

export class ProductService extends ServiceBase {
  async delete(params: EntityDto): Promise<void> {
    await this.httpSvc.delete('/api/services/app/Product/Delete', { params });
  }

  async get(params: EntityDto): Promise<ProductDto> {
    const result = await this.httpSvc.get('api/services/app/Product/Get', { params });
    return result.data.result;
  }

  async getAll(params: GetAllProductsInput): Promise<PagedFilteredAndSortedProductResponse<ProductDto>> {
    const result = await this.httpSvc.get('api/services/app/Product/GetAll', { params });
    return result.data.result;
  }

  async put(input: UpdateProductInput): Promise<ProductDto> {
    const result = await this.httpSvc.put('/api/services/app/Product/Update', input);
    return result.data.result;
  }
}

export default new ProductService();
