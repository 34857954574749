import _ from 'lodash';
import { action, observable } from 'mobx';

import warningService, { WarningService, WarningDto, CreateWarningInput, GetAllWarningsInput, UpdateWarningInput } from '../services/warning';
import { EntityDto } from '../services/dto/entityDto';
import { PagedResultDto } from '../services/dto/pagedResultDto';

export interface IWarningStore {
  warnings: PagedResultDto<WarningDto>;

  create(input: CreateWarningInput): Promise<void>;
  delete(params: EntityDto): Promise<void>;
  getAll(params: GetAllWarningsInput): Promise<void>;
  resetWarnings(): void;
  update(input: UpdateWarningInput): Promise<void>;
}

export default class WarningStore implements IWarningStore {
  @observable warnings: PagedResultDto<WarningDto>;

  private warningService: WarningService;

  constructor(warningSvc: WarningService = warningService) {
    this.warningService = warningSvc;
  }

  @action
  async getAll(params: GetAllWarningsInput): Promise<void> {
    const result = await this.warningService.getAll(params);
    this.warnings = result;
  }

  @action
  async delete(params: EntityDto<number>): Promise<void> {
    await this.warningService.delete(params);
    this.warnings.items = this.warnings.items.filter((x) => x.id !== params.id);
    this.warnings.totalCount -= 1;
  }

  @action
  async create(input: CreateWarningInput): Promise<void> {
    const result = await this.warningService.create(input);
    this.warnings.items = _.orderBy([...this.warnings.items, result], 'creationTime', 'desc');
    this.warnings.totalCount += 1;
  }

  @action
  resetWarnings(): void {
    this.warnings = undefined;
  }

  @action
  async update(input: UpdateWarningInput): Promise<void> {
    const result = await this.warningService.update(input);
    this.warnings.items = this.warnings.items.map((x) => (x.id === input.id ? result : x));
  }
}
