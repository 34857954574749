import { BrandDto } from './brand';
import ServiceBase from './core/serviceBase';
import { EditPropertyDto } from './dto/editPropertyDto';
import { EntityDto } from './dto/entityDto';
import { PagedFilteredAndSortedResultRequest } from './dto/pagedFilteredAndSortedResultRequest';
import { PagedResultDto } from './dto/pagedResultDto';
import { PropertyDto } from './dto/propertyDto';
import { UpdatePropertyDto } from './dto/updatePropertyDto';

export type BrandLibraryPropertyDto = PropertyDto & {
  brandLibraryId: number;
};

export type EditBrandLibraryPropertyDto = EditPropertyDto & {
  brandLibraryId: number;
};

export type UpdateBrandLibraryPropertyDto = UpdatePropertyDto & {
  brandLibraryId: number;
};

export type UpdateBrandLibraryInput = EntityDto & {
  properties: Array<UpdatePropertyDto>;
};

export type GetAllBrandLibrariesInput = PagedFilteredAndSortedResultRequest & {
  manufacturerLibraryId?: number;
};

export class BrandLibraryService extends ServiceBase {
  async delete(params: EntityDto): Promise<void> {
    await this.httpSvc.delete('/api/services/app/BrandLibrary/Delete', { params });
  }

  async get(params: EntityDto): Promise<BrandDto> {
    const result = await this.httpSvc.get('/api/services/app/BrandLibrary/Get', { params });
    return result.data.result;
  }

  async getAll(params: GetAllBrandLibrariesInput): Promise<PagedResultDto<BrandDto>> {
    const result = await this.httpSvc.get('/api/services/app/BrandLibrary/GetAll', { params });
    return result.data.result;
  }

  async post(input: EntityDto): Promise<BrandDto> {
    const result = await this.httpSvc.post('/api/services/app/BrandLibrary/Create', input);
    return result.data.result;
  }

  async put(input: UpdateBrandLibraryInput): Promise<BrandDto> {
    const result = await this.httpSvc.put('/api/services/app/BrandLibrary/Update', input);
    return result.data.result;
  }
}

export default new BrandLibraryService();
