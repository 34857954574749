import ServiceBase from './core/serviceBase';
import { EditPropertyDto } from './dto/editPropertyDto';
import { EntityDto } from './dto/entityDto';
import { PagedFilteredAndSortedProductRequest } from './dto/pagedFilteredAndSortedProductRequest';
import { PagedFilteredAndSortedProductResponse } from './dto/pagedFilteredAndSortedProductResponse';
import { PropertyDto } from './dto/propertyDto';
import { UpdatePropertyDto } from './dto/updatePropertyDto';
import { ProductDto } from './product';

export type CompetitorProductDto = ProductDto & {
  commentCount: number;
  competitorId: number;
  documentCount: number;
  mediaItemCount: number;
  warningCount: number;
};

export type CompetitorProductPropertyDto = PropertyDto & {
  competitorProductId: number;
};

export type EditCompetitorProductPropertyDto = EditPropertyDto & {
  competitorProductId: number;
};

export type UpdateCompetitorProductPropertyDto = UpdatePropertyDto & {
  competitorProductId: number;
};

export type UpdateCompetitorProductInput = EntityDto & {
  properties: Array<UpdatePropertyDto>;
};

export type CreateCompetitorProductInput = {
  competitorId: number;
  productId: number;
};

export type GetAllCompetitorProductsInput = PagedFilteredAndSortedProductRequest & {
  competitorId?: number;
  name?: string;
};

export class CompetitorProductService extends ServiceBase {
  async delete(params: EntityDto): Promise<void> {
    await this.httpSvc.delete('/api/services/app/CompetitorProduct/Delete', { params });
  }

  async get(params: EntityDto): Promise<CompetitorProductDto> {
    const result = await this.httpSvc.get('api/services/app/CompetitorProduct/Get', { params });
    return result.data.result;
  }

  async getAll(params: GetAllCompetitorProductsInput): Promise<PagedFilteredAndSortedProductResponse<CompetitorProductDto>> {
    const result = await this.httpSvc.get('api/services/app/CompetitorProduct/GetAll', { params });
    return result.data.result;
  }

  async post(input: CreateCompetitorProductInput): Promise<CompetitorProductDto> {
    const result = await this.httpSvc.post('/api/services/app/CompetitorProduct/Create', input);
    return result.data.result;
  }

  async put(input: UpdateCompetitorProductInput): Promise<CompetitorProductDto> {
    const result = await this.httpSvc.put('/api/services/app/CompetitorProduct/Update', input);
    return result.data.result;
  }
}

export default new CompetitorProductService();
