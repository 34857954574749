import { action, observable } from 'mobx';

import competitorProductService, {
  CompetitorProductDto,
  CompetitorProductService,
  CreateCompetitorProductInput,
  GetAllCompetitorProductsInput,
  UpdateCompetitorProductInput,
} from '../services/competitorProduct';
import { EntityDto } from '../services/dto/entityDto';
import { PagedFilteredAndSortedProductResponse } from '../services/dto/pagedFilteredAndSortedProductResponse';

export interface ICompetitorProductStore {
  competitorProduct: CompetitorProductDto;
  competitorProducts: PagedFilteredAndSortedProductResponse<CompetitorProductDto>;

  delete(input: EntityDto): Promise<void>;
  get(entity: EntityDto): Promise<void>;
  getAll(input: GetAllCompetitorProductsInput): Promise<void>;
  post(input: CreateCompetitorProductInput): Promise<CompetitorProductDto>;
  put(input: UpdateCompetitorProductInput): Promise<void>;
  resetCompetitorProduct(): void;
  resetCompetitorProducts(): void;
}

export default class CompetitorProductStore implements ICompetitorProductStore {
  @observable competitorProduct!: CompetitorProductDto;

  @observable competitorProducts!: PagedFilteredAndSortedProductResponse<CompetitorProductDto>;

  private competitorProductService: CompetitorProductService;

  constructor(competitorProductSvc: CompetitorProductService = competitorProductService) {
    this.competitorProductService = competitorProductSvc;
  }

  @action
  async delete(params: EntityDto<number>): Promise<void> {
    await this.competitorProductService.delete(params);

    if (this.competitorProducts) {
      this.competitorProducts.items = this.competitorProducts.items.filter((x) => x.id !== params.id);
      this.competitorProducts.totalCount -= 1;
    }
  }

  @action
  async get(input: EntityDto): Promise<void> {
    const result = await this.competitorProductService.get(input);
    this.competitorProduct = result;
  }

  @action
  async getAll(input: GetAllCompetitorProductsInput): Promise<void> {
    const result = await this.competitorProductService.getAll(input);
    this.competitorProducts = result;
  }

  post(input: CreateCompetitorProductInput): Promise<CompetitorProductDto> {
    return this.competitorProductService.post(input);
  }

  @action
  async put(input: UpdateCompetitorProductInput): Promise<void> {
    const result = await this.competitorProductService.put(input);
    this.competitorProduct = result;
  }

  @action
  resetCompetitorProduct(): void {
    this.competitorProduct = undefined;
  }

  @action
  resetCompetitorProducts(): void {
    this.competitorProducts = undefined;
  }
}
