import { action, observable } from 'mobx';

import accountService, { AccountService, IsTenantAvaibleResponse, RequestPasswordResetRequest } from '../services/account';

export interface IAccountStore {
  isTenantAvailable(tenancyName: string): Promise<IsTenantAvaibleResponse>;
  tenant?: IsTenantAvaibleResponse;
  resetTenant(): void;
  requestPasswordReset(requestPasswordReset: RequestPasswordResetRequest): Promise<void>;
}

export default class AccountStore {
  @observable tenant?: IsTenantAvaibleResponse;

  private accountService: AccountService;

  constructor(accountSvc: AccountService = accountService) {
    this.accountService = accountSvc;
  }

  @action
  isTenantAvailable = async (tenancyName: string): Promise<IsTenantAvaibleResponse> => {
    this.tenant = await this.accountService.isTenantAvailable({ tenancyName });
    return this.tenant;
  };

  @action
  resetTenant(): void {
    this.tenant = undefined;
  }

  @action
  requestPasswordReset = async (requestPasswordReset: RequestPasswordResetRequest): Promise<void> => {
    await this.accountService.requestPasswordReset(requestPasswordReset);
  };
}
