import ServiceBase from './core/serviceBase';
import { EntityDto } from './dto/entityDto';
import { PagedResultDto } from './dto/pagedResultDto';

export type WarningDto = EntityDto & {
  content: string;
  creationTime: Date;
  creatorUser: string;
  creatorUserId: number;
};

export type CreateWarningInput = {
  comparisonLineItemId?: number;
  content: string;
};

export type GetAllWarningsInput = {
  afterUnixTimestamp?: number;
  beforeUnixTimestamp?: number;
  comparisonLineItemId?: number;
  maxResultCount: number;
};

export type UpdateWarningInput = {
  id: number;
  content: string;
};

export class WarningService extends ServiceBase {
  async create(input: CreateWarningInput): Promise<WarningDto> {
    const result = await this.httpSvc.post('/api/services/app/Warning/Create', input);
    return result.data.result;
  }

  async delete(params: EntityDto): Promise<void> {
    await this.httpSvc.delete('/api/services/app/Warning/Delete', { params });
  }

  async getAll(params: GetAllWarningsInput): Promise<PagedResultDto<WarningDto>> {
    const result = await this.httpSvc.get('/api/services/app/Warning/GetAll', { params });
    return result.data.result;
  }

  async update(input: UpdateWarningInput): Promise<WarningDto> {
    const result = await this.httpSvc.put('/api/services/app/Warning/Update', input);
    return result.data.result;
  }
}

export default new WarningService();
