import { Spin } from 'antd';
import React from 'react';
import { LoadingComponentProps } from 'react-loadable';

type Props = LoadingComponentProps;

const Loading: React.FC<Props> = (props: Props) => {
  const { error, pastDelay } = props;

  if (error) {
    throw error;
  }

  if (pastDelay) {
    return (
      <div style={{ paddingTop: 100, textAlign: 'center' }}>
        <Spin size="large" />
      </div>
    );
  }

  return null;
};

export default Loading;
