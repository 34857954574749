import React from 'react';

type Props = {
  children?: React.ReactNode;
  version: string;
};

const semverGreaterThan = (versionA: string, versionB: string) => {
  const versionsA = versionA.split(/\./g);
  const versionsB = versionB.split(/\./g);

  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());

    if (a === b) {
      continue;
    }

    return a > b || Number.isNaN(b);
  }

  return false;
};

const refreshCacheAndReload = (): Promise<void> => {
  let promise = new Promise<void>((res) => {
    // delete browser cache and hard reload
    window.location.reload(true);
    res();
  });

  if (caches) {
    // Service worker cache should be cleared with caches.delete()
    promise = caches
      .keys()
      .then((names: string[]) => Promise.all(names.map((name) => caches.delete(name))))
      .then(() => promise);
  }

  return promise;
};

const CacheBuster: React.FC<Props> = ({ children, version: currentVersion }: Props) => {
  const [isLatestBuild, setIsLatestBuild] = React.useState(true);

  React.useEffect(() => {
    fetch('/meta.json', { cache: 'no-store' })
      .then((response) => response.json())
      .then((meta) => {
        const latestVersion = meta.version;

        const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);

        if (shouldForceRefresh) {
          setIsLatestBuild(false);
        } else {
          setIsLatestBuild(true);
        }
      });
  }, []);

  React.useEffect(() => {
    if (!isLatestBuild) {
      refreshCacheAndReload();
    }
  }, [isLatestBuild]);

  return <>{isLatestBuild ? children : null}</>;
};

export default CacheBuster;
