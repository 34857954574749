import ServiceBase from './core/serviceBase';
import { EntityDto } from './dto/entityDto';
import { PagedAndSortedRequest } from './dto/pagedAndSortedRequest';
import { PagedResultDto } from './dto/pagedResultDto';

export type CreateRoleInput = {
  name: string;
  displayName: string;
  normalizedName: string;
  description: string;
  grantedPermissions: Array<string>;
};

export type GetAllRolesInput = PagedAndSortedRequest & {
  keyword: string;
};

export type PermissionDto = {
  description: string;
  displayName: string;
  id: number;
  name: string;
};

export type RoleDto = {
  description: string;
  displayName: string;
  grantedPermissions: string[];
  id: number;
  isDefault: boolean;
  isStatic: boolean;
  name: string;
  normalizedName: string;
};

export class RoleService extends ServiceBase {
  async create(input: CreateRoleInput): Promise<RoleDto> {
    const result = await this.httpSvc.post('api/services/app/Role/Create', input);
    return result.data.result;
  }

  async delete(entityDto: EntityDto): Promise<void> {
    await this.httpSvc.delete('api/services/app/Role/Delete', { params: entityDto });
  }

  async get(entityDto: EntityDto): Promise<RoleDto> {
    const result = await this.httpSvc.get('api/services/app/Role/Get', { params: entityDto });
    return result.data.result;
  }

  async getAll(input: GetAllRolesInput): Promise<PagedResultDto<RoleDto>> {
    const result = await this.httpSvc.get('api/services/app/Role/GetAll', { params: input });
    return result.data.result;
  }

  async getAllPermissions(): Promise<PermissionDto[]> {
    const result = await this.httpSvc.get('api/services/app/Role/GetAllPermissions');
    return result.data.result.items;
  }

  async update(updateRoleRequest: RoleDto): Promise<RoleDto> {
    const result = await this.httpSvc.put('api/services/app/Role/Update', updateRoleRequest);
    return result.data.result;
  }
}

export default new RoleService();
