import ServiceBase from './core/serviceBase';
import { EntityDto } from './dto/entityDto';

type ApplicationInfo = {
  version: string;
  releaseDate: Date;
  features: Feature[];
};

type Feature = {
  name: string;
  included: boolean;
};

type TenantLoginInfo = EntityDto & {
  tenancyName: string;
  name: string;
};

type UserLoginInfo = EntityDto & {
  name: string;
  surname: string;
  userName: string;
  emailAddress: string;
};

export type GetCurrentLoginInformation = {
  application: ApplicationInfo;
  tenant: TenantLoginInfo;
  user: UserLoginInfo;
};

export class SessionService extends ServiceBase {
  async getCurrentLoginInformations(): Promise<GetCurrentLoginInformation> {
    const result = await this.httpSvc.get('api/services/app/Session/GetCurrentLoginInformations', {
      headers: {
        'Abp.TenantId': abp.multiTenancy.getTenantIdCookie(),
      },
    });

    return result.data.result;
  }
}

export default new SessionService();
