import { action, observable } from 'mobx';

import sessionService, { GetCurrentLoginInformation, SessionService } from '../services/session';

export interface ISessionStore {
  currentLogin: GetCurrentLoginInformation;

  getCurrentLoginInformations(): Promise<void>;
}

export default class SessionStore implements ISessionStore {
  @observable currentLogin!: GetCurrentLoginInformation;

  private sessionService: SessionService;

  constructor(sessionSvc: SessionService = sessionService) {
    this.sessionService = sessionSvc;
  }

  @action
  async getCurrentLoginInformations(): Promise<void> {
    const result = await this.sessionService.getCurrentLoginInformations();
    this.currentLogin = result;
  }
}
